<template>
  <div :class="['proyecto', 'grid', 'grid-gap-md', datos_proy.nombre, hover ? 'hover' : '']"
    :style="datos_proy.estilos"
  >
    <div class="child col-5@sm descripcion">
      <div class="titulo">
        <img class="logo" v-if="hover && datos_proy.images.logo" :src="datos_proy.images.logo" :alt="datos_proy.images.logo_alt">
        <h1 class="tit nombre" v-else v-html="datos_proy.nombre_logo"></h1>
        <!-- <h3 class="subtit" v-if="datos_proy.datos.subtit">{{datos_proy.datos.subtit}}</h3> -->
        <ul class="labels">
          <template v-if="wwidth < 600">
            <li class="parr label" v-for="(label, index) in datos_proy.datos.labels.papel" :key="index">{{label}}</li>
            <li class="break"></li>
          </template>
          <li class="parr label" v-for="(label, index) in datos_proy.datos.labels.tipo_cliente[idioma]" :key="index">{{label}}</li>
        </ul>
        <!-- <svg v-if="(wwidth < 600)" class="vermas" width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path v-if="!hover" d="M0.875 1.5L8.18784 6.375L15.5 1.5" stroke="#12328B" stroke-width="1.625" stroke-linejoin="round"/>
          <path v-else d="M15.5 6.375L8.18716 1.5L0.874999 6.375" stroke="#12328B" stroke-width="1.625" stroke-linejoin="round"/>
        </svg> -->
      </div>
      <transition name="appear_top" :mode="'out-in'">
        <div v-if="(wwidth > 599) && hover" class="texto">
          <p class="parr" v-html="datos_proy.descripcion[idioma]"></p>
          <button v-if="datos_proy.links.link_text" :disabled="!datos_proy.links.cta" @click="ctaLink(datos_proy.links.cta)" class="boton">{{datos_proy.links.link_text[idioma]}}</button>
        </div>
      </transition>
    </div>
    <transition name="appear_right">
      <div v-if="(wwidth < 600) || hover" class="child col-7@sm mockup">
        <div class="mancha_fondo"></div>
        <img class="contain" :style="datos_proy.images.sombra ? 'filter: drop-shadow(4px 4px 15px rgba(0,0,0,.2))' : ''" :src="datos_proy.images.mockup" :alt="datos_proy.images.logo_alt">
      </div>
    </transition>
    <transition name="appear_top" :mode="'out-in'">
        <div v-if="(wwidth < 600)" class="texto">
          <p class="parr" v-html="datos_proy.descripcion[idioma]"></p>
          <button v-if="datos_proy.links.link_text" :disabled="!datos_proy.links.cta" @click.stop="ctaLink(datos_proy.links.cta)" class="boton">{{datos_proy.links.link_text[idioma]}}</button>
        </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'proyecto',
  props: {
    datos_proy: {},
    hover: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // hover: false
      wwidth: 0
    }
  },
  computed: {
    ...mapState(['idioma'])
  },
  created: function() {
    this.wwidth = window.innerWidth;
  },
  methods: {
    ctaLink(link) {
      if (link === 'case') {
        this.$router.push({name: this.datos_proy.nombre});
      } else {
        window.open(this.datos_proy.links.cta);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.proyecto {
  height: 10vh;
  margin: 30vh 0;
  position: relative;
  transition: all .3s ease-in-out;
  // display: flex;
  // flex-direction: column; 
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: center;
  &.hover {
    height: 50vh;
    margin-bottom: -20vh;
    transition: all .7s ease-in-out;
    .nombre, .subtit, .datos {color: var(--color_proyecto);}
  }
  &.ampliado {
    margin: 1.5rem;
    height: calc(100vh - 3rem);
    background-color: var(--fondo_proyecto);
  }
  --color_proyecto: var(--font_parr_serif);
  --font_proyecto: var(--font_tit_sans);
  .descripcion {
    // width: 60%;
    // padding: 2em 0;
    transition: all 1s ease-in-out;
    margin-right: -22%;
    z-index: 1;
    .titulo {
      // margin-top: 10%;
      .logo {
        max-width: 30rem;
        height: clamp(8rem, 10%, 12rem);
        margin-bottom: 1em;
      }
      .nombre {
        // font-size: 4rem;
        font-family: var(--font_proyecto);
        margin-bottom: .2em;
      }
      // .vermas {
        // margin-top: .5em;
        // path {fill: var(--color_proyecto);}
        // path {fill: var(--color_base_dark);}
      // }
      .labels {
        display: flex;
        flex-wrap: wrap;
        margin: .5em 0;
        .label {
          &:first-child {margin-left: 0;}
          margin: .3em;
          border: 1px solid var(--color_base_dark);
          padding: .2em .5em;
          white-space: nowrap;
        }
        .break {
          flex-basis: 100%;
          height: 0;
          &+.label {
            margin-left: 0;
          }
        }
      }
    }
  }
  .texto {
    max-height: 800px;
    overflow: hidden;
    .parr {
      color: var(--color_proyecto);
      margin: 1.2em 0;
    }
  }
  .mockup {
    position: relative;
    width: 100%;
    height: 90%;
    max-height: calc(96vh - 3rem);
    margin-left: auto;
    img {
      position: absolute;
      right: 0;
      z-index: 1;
      width: 80%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      &.contain {object-fit: contain;}
    }
    .mancha_fondo {
      position: absolute;
      top: 30%;
      right: -40%;
      height: 40%;
      width: 140%;
      background: var(--fondo_proyecto);
      z-index: 0;
    }
  }
  .boton {
    background-color: var(--color_proyecto);
    color: white;
    border-radius: 2em;
    margin-left: 0;
  }
}
@media (max-width: 599px) {
  .proyecto {
    max-height: unset;
    // &.hover {
      height: auto;
      margin-bottom: -20vh;
    transition: all .7s ease-in-out;
    .nombre, .subtit, .datos {color: var(--color_proyecto);}
    // }
    .descripcion {
      width: 100%;
      // &.texto {order: 3;}
    }
    .mockup {
      // order: 2;
      width: 100%;
      max-height: 25vh;
      height: 25vh;
      .contain {left: 10%;}
    }
  }
}
</style>
