import { createStore } from 'vuex'

export default createStore({
  state: {
    idioma: localStorage.getItem('lang') || undefined
  },
  mutations: {
    SET_IDIOMA(state, lang) {
      localStorage.setItem('lang', lang);
			state.idioma = lang;
    },
  },
  actions: {
    setIdioma({commit}, data) {
      commit('SET_IDIOMA', data);
    },
  }
})
