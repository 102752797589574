<template>
  <div class="footer">
    <!-- <div class="row"> -->
      <h3 class="tit yo">{{textos[idioma].algomassobre}}</h3>
    <!-- </div> -->
    <div class="row">
      <!-- <p class="medparr yo">
        Joven coruñés con aficiones diversas, entre ellas la de buscar nuevas aficiones y cosas que aprender. Al parecer también le gusta hablar de sí mismo en tercera persona.
      </p> -->
      <!-- <p class="parr yo">
        Experto en caer bien y levantarme a volver a intentarlo.
      </p> -->
      <!-- <p class="parr yo">
        Me encanta aprender y probar cosas nuevas, lo último con lo que me he atrevido creo que es a montar en longboard (estoy en ello). En mi lista para este año tengo pendiente teatro, canto (lo hago bochornosamente mal), o dibujo.
      </p> -->
      <p class="parr yo">{{textos[idioma].soydelosque}}</p>
    </div>
    <div class="row">
      <p class="parr yo" v-html="textos[idioma].aquiosdejo"></p>
      <p class="parr yo" v-html="textos[idioma].enalgunmomento"></p>
      <div class="pinterest">
        <a href="https://www.pinterest.es/japimes/design/ui-+-ux/"
          data-pin-do="embedBoard"
          data-pin-scale-height="130"
          data-pin-scale-width="100">
        </a>
      </div>
    </div>
    <p class="parr yo" v-html="textos[idioma].estoyactualmente"></p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'futer',
  computed: {
    ...mapState(['idioma'])
  },
  data() {
    return {
      textos: {
        es: {
          algomassobre: 'Algo más sobre mí',
          soydelosque: 'Soy de los que suelen cocinar sin los ingredientes exactos; de los que le gusta empezar las jugadas desde atrás, distribuyendo el juego con una visión clara del campo; de los que les gusta construir canciones en grupo, partiendo de cualquier idea y, dándole vueltas, cada uno añadiendo sobre lo anterior, ver como crece; de los que aprenden probando y cayéndose, hasta que entienden por qué se han caido...',
          aquiosdejo: 'Aquí os dejo <a href="https://www.pinterest.es/japimes" target="blank">mi Pinterest</a>, donde podéis ver cosas que inspiran y que voy guardando (pequeña parte de mi diógenes digital...), y <a href="https://www.flickr.com/photos/japimes" target="_blank">mi Flickr</a> desactualizado.',
          enalgunmomento: 'En algún momento espero poner un bandcamp también... (forma sutil de sugerir que estoy en un grupo de música).',
          estoyactualmente: 'Estoy actualmente trabajando en completar y mejorar esto.<br>\
                            Si quieres saber más o te interesa mi perfil, no dudes en contactarme en <a href="mailto:hola@mese.es" target="blank">hola@mese.es</a>'
        },
        en: {
          algomassobre: 'Some more info about me',
          soydelosque: 'I am the kind of person who is used to cook without the exact or all the ingredients; who likes starting the sport plays from the start, distributing the game with a clear field vision; who enjoys building songs as a team, starting from any idea and iterating, with each one adding over the last part, and see how it grows beautiful; who learns by trying and falling until they understand why they fell...',
          aquiosdejo: 'Here I leave <a href="https://www.pinterest.es/japimes" target="blank">my Pinterest</a>, where you can see some of the things that inspire me (part of my digital diogenes...), and <a href="https://www.flickr.com/photos/japimes" target="_blank">my outdated Flickr</a>.',
          enalgunmomento: 'At some time I hope to publish also a bandcamp... (unsubtle way of saying that I have a band...).',
          estoyactualmente: 'I am currently working to improve this site.<br>\
                            If you want to know more about any of my projects or you are interested in my profile, have no doubts in getting in touch with me at <a href="mailto:hola@mese.es" target="blank">hola@mese.es</a>'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  margin-bottom: -15vh;
  .row {margin-bottom: 3em;}
  &:deep a {
    font-weight: bold;
    font-family: "Mallory", Helvetica, Arial, sans-serif;
  }
  .pinterest {
    position: relative;
    display: block;
    width: 50%;
  }
  @media (max-width: 599px) {
    .pinterest {width: 100%;}
  }
}
</style>
