<template>
    <a @click="$router.go(-1)">← {{idioma === 'en' ? 'go back' : 'volver'}}</a>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'volver',
  computed: {
    ...mapState(['idioma'])
  },
}
</script>

<style scoped lang="scss">
a {
    position: absolute;
    top: 3vh;
    left: 0;
    color: var(--color_accent);
    font-family: "Mallory", Helvetica, Arial, sans-serif;
  }
</style>
