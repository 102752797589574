<template>
  <div class="footer">
    <a @click="$router.go(-1)">← {{idioma === 'en' ? 'go back' : 'volver'}}</a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'futer_cases',
  computed: {
    ...mapState(['idioma'])
  },
}
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  margin-top: 15vh;
  // background-color: var(--color_accent);
  a {
    color: var(--color_accent);
    font-weight: bold;
    font-family: "Mallory", Helvetica, Arial, sans-serif;
  }
  @media (max-width: 599px) {
  }
}
</style>
