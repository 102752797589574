<template>
  <div :class="['case', scroll_gris ? 'gris' : '', scroll_amarillo ? 'amarillo' : '']">
    <section class="intro">
      <div class="fondo_full_page"></div>
      <volver />
      <h1 class="tit" v-if="(currentScrollPosition < wheight) || !currentScrollPosition">UCI<span>PLUS</span></h1>
      <div id="video_intro" class="mockup_gif">
        <img class="mockup_mac" src="/images/proy_uciplus/macb.png"/>
        <video ref="video_intro" class="mockup_in" tabindex="0" autobuffer muted preload loop playsinline>
          <source src="/images/proy_uciplus/home.mp4" type="video/mp4">
          <p>Your user agent does not support the HTML5 Video element.</p>
        </video>
      </div>
      <span class="fondo"></span>
    </section>
    <section class="resum">
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].objetivo}}</p>
        <h3 class="subtit">{{textos[idioma].renovar}}</h3>
      </div>
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].donde}}</p>
        <p class="parr donde">
          <span><b>2015</b></span><br>
          <span><b>UCI</b></span>
        </p>
      </div>
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].labor}}</p>
        <div class="res_icons">
          <p class="datos"><b>Branding</b></p>
          <p class="datos">
            <b>{{textos[idioma].diseñouxui}}</b> <small>.ai .psd</small>
          </p>
          <p class="datos">
            <b>{{textos[idioma].desarrolloweb}}</b> <small>.aspx/.php .scss .js</small>
          </p>
        </div>
      </div>
    </section>
    <section class="contexto">
      <h3 class="tit">{{textos[idioma].dequevaesto}}</h3>
      <p class="parr">{{textos[idioma].trabajeenel}}</p>
      <p class="parr" v-html="textos[idioma].unodelosproyectos"></p>
      <div class="problema row">
        <p class="parr" v-html="textos[idioma].problema"></p>
        <p class="parr">{{textos[idioma].problema2}}</p>
        <!-- <p class="parr">
          <b>SOLUCIÓN</b><br>
          <b>
            Crear una experiencia fresca y colorida que consiga que el usuario desconecte y le transporte a los viajes de ocio/trabajo. <br>
            Sin perder de vista la dinámica del programa y lo que hace falta para conseguir cada cosa.
          </b>
        </p> -->
      </div>
    </section>
    <section class="propuesta">
      <div class="mi_propuesta">
        <h3 class="tit">{{textos[idioma].propuesta}}</h3>
        <p class="parr">{{textos[idioma].trasrealizar}}</p>
        <p class="parr">{{textos[idioma].observeque}}<br><br>{{textos[idioma].nuestroprograma}}</p>
        <p class="parr">{{textos[idioma].inspiradoeneste}}</p>
      </div>
      <img class="tit" src="/images/proy_uciplus/propuesta_tit.png"/>
      <div class="propuesta_gris" ref="prop_gris">
        <h3 class="tit">{{textos[idioma].elmundogris}}</h3>
        <div class="cols">
          <div class="texto">
            <p class="parr" v-html="textos[idioma].estemundoes"></p>
            <p class="parr">{{textos[idioma].aquiesdonde}}</p>
            <p class="parr">{{textos[idioma].perohayotromundo}}</p>
          </div>
          <div class="slider center-col">
            <!-- <transition name="slide_left" mode="out-in"> -->
              <img v-if="slide.gris === 1" class="mockup" src="/images/proy_uciplus/tablet.png" />
              <img v-else-if="slide.gris === 2" class="mockup" src="/images/proy_uciplus/tablet1.png" />
              <img v-else-if="slide.gris === 3" class="mockup" src="/images/proy_uciplus/tablet2.png" />
            <!-- </transition> -->
            <button class="boton slider-flecha" @click="avanzarSlide('gris', 3)">
              <svg width="24" height="41" viewBox="0 0 24 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2L20.7754 20.7754L2.24918 39.3016" stroke="#4A4A4A" stroke-width="4"/>
              </svg>
            </button>
          </div>
        </div>
        <!-- <span class="fondo"></span> -->
      </div>
      <div class="propuesta_color cols" ref="prop_amarillo">
        <div class="mok" id="video_color">
          <h3 class="tit" v-if="wwidth < 600">{{textos[idioma].elmundouciplus}}</h3>
          <video id="v0" tabindex="0" autobuffer muted preload>
            <source src="/images/proy_uciplus/propuesta_color.mp4" type="video/mp4">
            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>
        </div>
        <div class="fondo_nar">
          <div class="mundo">
            <h3 class="tit" v-if="wwidth > 599">{{textos[idioma].elmundouciplus}}</h3>
            <p class="parr" v-html="textos[idioma].esteesunmundo"></p>
            <p class="parr" v-html="textos[idioma].aquisolose"></p>
            <p class="parr" v-html="textos[idioma].desdesusoficinas"></p>
            <p class="parr" v-html="textos[idioma].disponendeun"></p>
          </div>
        </div>
      </div>
    </section>
    <section class="detalles">
      <h3 class="tit">{{textos[idioma].algunosdetalles}}</h3>
      <div class="fondo_full_page"></div>
      <div class="caja cols">
        <div class="mockups">
          <!-- <img class="mockup_imac" src="/images/proy_uciplus/imac.png"/> -->
          <div class="mockup_cont slider">
            <transition :name="slide_left ? 'slide_left' : 'slide_right'" mode="out-in">
              <img v-if="slide.detalles === 1" src="/images/proy_uciplus/detalles_punt.gif" />
              <img v-else-if="slide.detalles === 2" src="/images/proy_uciplus/detalles_hov.gif" />
              <img v-else-if="slide.detalles === 3" src="/images/proy_uciplus/detalles_prog.gif" />
              <img v-else-if="slide.detalles === 4" src="/images/proy_uciplus/detalles_foot.png" />
              <img v-else-if="slide.detalles === 5" src="/images/proy_uciplus/detalles_prem.gif" />
              <img v-else-if="slide.detalles === 6" src="/images/proy_uciplus/detalles_cir.gif" />
            </transition>
          </div>
        </div>
        <div class="puntos center">
          <button class="boton slider-flecha" @click="retroSlide('detalles', 6)">
            <svg width="24" height="41" viewBox="0 0 24 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.7754 39.3018L3.00001 20.5264L21.5262 2.00017" stroke="#4A4A4A" stroke-width="4"/>
            </svg>
          </button>
          <ul class="slider">
            <transition :name="slide_left ? 'slide_left' : 'slide_right'" mode="out-in">
              <li v-if="slide.detalles === 1" v-html="textos[idioma].lapantalladepuntos"></li>
              <li v-else-if="slide.detalles === 2" v-html="textos[idioma].accesibilidad1"></li>
              <li v-else-if="slide.detalles === 3" v-html="textos[idioma].lasreglasy"></li>
              <li v-else-if="slide.detalles === 4" v-html="textos[idioma].accesibilidad2"></li>
              <li v-else-if="slide.detalles === 5" v-html="textos[idioma].hayunajerarquia"></li>
              <li v-else-if="slide.detalles === 6" v-html="textos[idioma].lostestimonios"></li>
            </transition>
          </ul>
          <button class="boton slider-flecha" @click="avanzarSlide('detalles', 6)">
            <svg width="24" height="41" viewBox="0 0 24 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 2L20.7754 20.7754L2.24918 39.3016" stroke="#4A4A4A" stroke-width="4"/>
            </svg>
            <p>{{slide.detalles}}/6</p>
          </button>
        </div>
      </div>
    </section>
    <futer_cases />
  </div>
</template>

<script>
import futer_cases from "@/components/futer_cases.vue";
import volver from "@/components/volver.vue"
import { mapState } from 'vuex'

export default {
  name: 'uciplus',
  components: {futer_cases, volver},
  props: {
    currentScrollPosition: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      textos: {
        es: {
          objetivo: 'Objetivo:',
          renovar: 'Renovar y refrescar una marca a través de su web',
          donde: 'Dónde y cuando:',
          labor: 'Labor en el proyecto:',
          diseñouxui: 'Diseño UX-UI',
          desarrolloweb: 'Desarrollo web',
          dequevaesto: '¿DE QUÉ VA ESTO?',
          trabajeenel: 'Trabajé en el departamento creativo de una gran empresa de crédito inmobiliario que empezaba a darse cuenta de la importancia de cuidar su diseño e imagen.',
          unodelosproyectos: 'Uno de los proyectos que me encargaron fue este:<br>\
                        <b class="highlighted">rediseñar la web de su programa de puntos para profesionales inmobiliarios.</b>',
          problema: '<b>PROBLEMA</b><br>\
                    Su página no cumplía el objetivo principal del programa:<br>\
                    fidelizar a los socios motivándolos a querer sumar puntos vendiendo sus hipotécas.',
          problema2: 'El usuario no acababa de involucrarse con el programa ni de entender y motivarse con sus premios.',
          propuesta: 'MI PROPUESTA',
          trasrealizar: 'Tras realizar un estudio entrevistando a diferentes agentes inmobiliarios, mirando sus datos individuales de rendimiento en el programa de puntos, y analizando el uso de la web con herramientas como Google Analytics y Hotjar, llegué a una conclusión sobre la falta de interés.',
          observeque: 'Observé que estas personas trabajan de traje, en oficinas bastante grises y estériles. Pasan gran parte de su tiempo delante del ordenador, mirando informes y tablas tediosas llenas de datos.',
          nuestroprograma: 'Nuestro programa ofrecía, como recompensas, escapadas de ese mundo estático y gris hacia otro diametralmente opuesto. Un mundo donde vivir experiencias únicas y coloridas, donde “estirar” sus mentes.',
          inspiradoeneste: 'Inspirado en este contraste, plasmé dentro de la web esos dos mundos con estilos muy diferenciados.',
          elmundogris: 'EL MUNDO GRIS',
          estemundoes: 'Este mundo es más parecido al que están habituados, más parecido a su residencia habitual.<br>\
                        Un mundo serio, formal y profesional en el que la rutina del día a día, la oficina, las hipotecas, las ventas, las comisiones, el traje y la corbata rigen su tiempo.<br>\
                        Es así para que puedan escanear fácilmente la información, entenderla y encontrar lo que buscan con facilidad.',
          aquiesdonde: 'Aquí es donde reside toda la información referida a las bases y los datos técnicos del programa. Áreas de mera información limpia, ordenada y clara.',
          perohayotromundo: 'Pero hay otro mundo…',
          elmundouciplus: 'EL MUNDO UCIPLUS',
          esteesunmundo: 'Éste es un mundo con el que soñar despierto. <br>\
                          Un mundo lleno de color, movimiento, palmeras, piscinas y playas.',
          aquisolose: 'Aquí sólo se tienen que preocupar por saber a qué hora acaba el buffet libre, qué día es la excursión en barco, y en cuál de los hoteles de ensueño van a dormir cada noche.',
          desdesusoficinas: 'Desde sus oficinas tienen una ventana a este mundo a través de nuestra web. <br>\
                            Desde ella pueden ver a dónde ir, qué atractivo plan les espera allí, observar cada detalle visualizándose, imaginando y sintiendo que ya forman parte de ese paisaje.',
          disponendeun: 'Disponen de un área de usuario en el que pueden ver la distancia entre estos mundos.<br>\
                        A parte de mostrarles sus puntos, premios y la opción de canjearlos, se les lanzan mensajes de motivación para animarles avanzar y conseguir sus objetivos.',
          algunosdetalles: 'algunos detalles interesantes',
          lapantalladepuntos: '<p class="parr"><b>La pantalla de puntos es simple, interactiva, personalizada y cómoda</b>.</p>\
                <p class="parr">Puedes ver los puntos necesarios y los que te faltan para conseguir los premios. Se te muestran mensajes o consejos según el % que lleves, y dispones la opción de reservar la actividad o completar los puntos que te falten con dinero.</p>',
          accesibilidad1: '<p class="parr"><b>Accesibilidad (1)</b></p>\
                <p class="parr">Los iconos que enlazan a otras páginas, se <b>contextualizan en :hover</b>.</p>',
          lasreglasy: '<p class="parr">Las reglas y condiciones del programa son <b>accesibles desde cualquier punto de la web</b>, sin tener que recargar la página.</p>',
          accesibilidad2: '<p class="parr"><b>Accesibilidad (2)</b></p>\
                <p class="parr"><b>El footer</b> funciona a la vez de mapa de la web, teniendo la misma estructura y estilo.</p>',
          hayunajerarquia: '<p class="parr">Hay una jerarquía de premios (cada uno tiene su época del año).<br>\
                  La información de los premios secundarios se completa en :hover.</p>',
          lostestimonios: '<p class="parr">Los testimonios se presentan de forma dinámica y aleatoria para cubrir los de todos los premios y del programa en general.</p>',
        },
        en: {
          objetivo: 'Goal:',
          renovar: 'Renovar y refrescar una marca a través de su web',
          donde: 'When and where:',
          labor: 'My role:',
          diseñouxui: 'Diseño UX-UI',
          desarrolloweb: 'Desarrollo web',
          dequevaesto: 'WHAT IS THIS ABOUT?',
          trabajeenel: 'Trabajé en el departamento creativo de una gran empresa de crédito inmobiliario que empezaba a darse cuenta de la importancia de cuidar su diseño e imagen.',
          unodelosproyectos: 'Uno de los proyectos que me encargaron fue este:<br>\
                        <b class="highlighted">rediseñar la web de su programa de puntos para profesionales inmobiliarios.</b>',
          problema: '<b>PROBLEM</b><br>\
                    Su página no cumplía el objetivo principal del programa:<br>\
                    fidelizar a los socios motivándolos a querer sumar puntos vendiendo sus hipotécas.',
          problema2: 'El usuario no acababa de involucrarse con el programa ni de entender y motivarse con sus premios.',
          propuesta: 'MI PROPUESTA',
          trasrealizar: 'Tras realizar un estudio entrevistando a diferentes agentes inmobiliarios, mirando sus datos individuales de rendimiento en el programa de puntos, y analizando el uso de la web con herramientas como Google Analytics y Hotjar, llegué a una conclusión sobre la falta de interés.',
          observeque: 'Observé que estas personas trabajan de traje, en oficinas bastante grises y estériles. Pasan gran parte de su tiempo delante del ordenador, mirando informes y tablas tediosas llenas de datos.',
          nuestroprograma: 'Nuestro programa ofrecía, como recompensas, escapadas de ese mundo estático y gris hacia otro diametralmente opuesto. Un mundo donde vivir experiencias únicas y coloridas, donde “estirar” sus mentes.',
          inspiradoeneste: 'Inspirado en este contraste, plasmé dentro de la web esos dos mundos con estilos muy diferenciados.',
          elmundogris: 'EL MUNDO GRIS',
          estemundoes: 'Este mundo es más parecido al que están habituados, más parecido a su residencia habitual.<br>\
                        Un mundo serio, formal y profesional en el que la rutina del día a día, la oficina, las hipotecas, las ventas, las comisiones, el traje y la corbata rigen su tiempo.<br>\
                        Es así para que puedan escanear fácilmente la información, entenderla y encontrar lo que buscan con facilidad.',
          aquiesdonde: 'Aquí es donde reside toda la información referida a las bases y los datos técnicos del programa. Áreas de mera información limpia, ordenada y clara.',
          perohayotromundo: 'Pero hay otro mundo…',
          elmundouciplus: 'EL MUNDO UCIPLUS',
          esteesunmundo: 'Éste es un mundo con el que soñar despierto. <br>\
                          Un mundo lleno de color, movimiento, palmeras, piscinas y playas.',
          aquisolose: 'Aquí sólo se tienen que preocupar por saber a qué hora acaba el buffet libre, qué día es la excursión en barco, y en cuál de los hoteles de ensueño van a dormir cada noche.',
          desdesusoficinas: 'Desde sus oficinas tienen una ventana a este mundo a través de nuestra web. <br>\
                            Desde ella pueden ver a dónde ir, qué atractivo plan les espera allí, observar cada detalle visualizándose, imaginando y sintiendo que ya forman parte de ese paisaje.',
          disponendeun: 'Disponen de un área de usuario en el que pueden ver la distancia entre estos mundos.<br>\
                        A parte de mostrarles sus puntos, premios y la opción de canjearlos, se les lanzan mensajes de motivación para animarles avanzar y conseguir sus objetivos.',
          algunosdetalles: 'algunos detalles interesantes',
          lapantalladepuntos: '<p class="parr"><b>La pantalla de puntos es simple, interactiva, personalizada y cómoda</b>.</p>\
                <p class="parr">Puedes ver los puntos necesarios y los que te faltan para conseguir los premios. Se te muestran mensajes o consejos según el % que lleves, y dispones la opción de reservar la actividad o completar los puntos que te falten con dinero.</p>',
          accesibilidad1: '<p class="parr"><b>Accesibilidad (1)</b></p>\
                <p class="parr">Los iconos que enlazan a otras páginas, se <b>contextualizan en :hover</b>.</p>',
          lasreglasy: '<p class="parr">Las reglas y condiciones del programa son <b>accesibles desde cualquier punto de la web</b>, sin tener que recargar la página.</p>',
          accesibilidad2: '<p class="parr"><b>Accesibilidad (2)</b></p>\
                <p class="parr"><b>El footer</b> funciona a la vez de mapa de la web, teniendo la misma estructura y estilo.</p>',
          hayunajerarquia: '<p class="parr">Hay una jerarquía de premios (cada uno tiene su época del año).<br>\
                  La información de los premios secundarios se completa en :hover.</p>',
          lostestimonios: '<p class="parr">Los testimonios se presentan de forma dinámica y aleatoria para cubrir los de todos los premios y del programa en general.</p>',
        }
      },
      slide: {
        gris: 1,
        detalles: 1
      },
      scroll_gris: false,
      scroll_amarillo: false,
      wheight: 0,
      wwidth: 0,
      slide_left: false
    }
  },
  watch: {
    currentScrollPosition() {
      if (this.currentScrollPosition > 50) this.$refs.video_intro.play();
      this.scroll_gris = this.currentScrollPosition > (this.$refs.prop_gris.offsetParent.offsetTop + this.$refs.prop_gris.offsetTop - this.wheight/2);
      this.scroll_amarillo = this.currentScrollPosition > (this.$refs.prop_amarillo.offsetParent.offsetTop + this.$refs.prop_amarillo.offsetTop - this.wheight/2);
    }
  },
  computed: {
    ...mapState(['idioma'])
  },
  methods: {
    avanzarSlide(slider, max) {
      this.slide_left = false;
      if (this.slide[slider] < max) {
        this.slide[slider]++;
      } else {
        this.slide[slider] = 1;
      }
    },
    retroSlide(slider, max) {
      this.slide_left = true;
      if (this.slide[slider] > 1) {
        this.slide[slider]--;
      } else {
        this.slide[slider] = max;
      }
    },
    playVideo(contenedor, vid, top) {
      let that = this;
      let bound = document.querySelector(contenedor);
      let video = document.querySelector(vid);
      const scrollVideo = ()=>{
        if (video.duration) {
          const distanceFromTop = video.offsetTop + bound.offsetParent.offsetTop + (top ? that.wheight/top : 0);
          const rawPercentScrolled = (window.scrollY - distanceFromTop) / (distanceFromTop + bound.scrollHeight);
          const percentScrolled = Math.min(Math.max(rawPercentScrolled, 0), 1);
          video.currentTime = video.duration * percentScrolled;
        }
        requestAnimationFrame(scrollVideo);
      }
      requestAnimationFrame(scrollVideo);
    }
  },
  mounted: function() {
    this.wheight = window.innerHeight;
    this.wwidth = window.innerWidth;
    this.$nextTick(() => {
      // this.playVideo('#video_intro', '#video_intro video', 0);
      this.playVideo('#video_color', '#video_color video', 1);
    });
  }
}
</script>

<style scoped lang="scss">
.case {
  --azul: #0862C2;
  --gris_light: #e2e2e4;
  --gris_dark: #494949;
  --rosa: #E4008D;
  --verde: #248339;
  --amarillo: #FEF2B5;

  --font_parr_sans: "Lato-Regular", Helvetica, Arial, sans-serif;

  ::selection {
		background-color: var(--azul);
		color: white;
	}
  .tit {
    font-family: "Lato-Regular", Helvetica, Arial, sans-serif;
	}
	.parr-grande {
    font-family: "Calluna", "Times New Roman", Times, serif;
		line-height: 1.5em;
	}
  transition: background-color 1s ease;
  &.gris {background-color: var(--gris_light);}
  &.amarillo {background-color: var(--amarillo);}
}
section {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}
.slider {
  &>* {
    position: relative;
    left: 0;
  }
  &-flecha {
    box-shadow: none;
    z-index: 10;
  }
}
.intro {
  width: 100%;
  height: 100vh;
  padding-top: 35vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  .fondo_full_page {
    height: 80%;
    top: 0;
    z-index: -1;
    background-color: #E4E5E6;
  }
  .tit {
    color: white;
    position: fixed;
    top: 25vh;
    font-size: 7vw;
    margin-top: 0;
  }
  .mockup_gif {
    display: block;
    position: relative;
    z-index: 5;
    width: 85%;
    text-align: center;
    .mockup_mac {
      width: 100%;
    }
    .mockup_in {
      position: absolute;
      width: 71%;
      top: clamp(1rem, 3vw,47px);
      left: 15%;
      z-index: -1;
    }
  }
}
.resum {
  margin: 10vh auto 15vh;
  .subseccion {
    display: flex;
    margin: 1em auto;
    .descriptivo {width: 12em;}
    .res_icons p {font-family: var(--font_parr_sans);}
  }
}
.contexto {
  .tit {
    color: var(--gris_light);
  }
  ::v-deep .highlighted {
    display: inline-block;
    background-color: var(--azul);
    padding: .1em .4em;
    text-align: center;
  }
}
.propuesta {
  max-width: unset;
  margin-top: 20vh;
  .propuesta_gris, .mi_propuesta {
    position: relative;
    max-width: 1000px;
    margin: 30vh auto;
  }
  .mi_propuesta {
    .tit {
      color: var(--azul);

    }
    .parr {
      color: var(--azul);
    }
  }
  img.tit {
    width: clamp(15rem, 30%, 25rem);
    display: block;
    margin: 5em auto;
  }
  .propuesta_gris {
    .texto {
      flex-grow: 1.5;
      display: flex;
      flex-flow: column;
      justify-content: center;
      .parr:last-child {margin-top: 2em;}
    } 
  }
  .propuesta_color {
    background-image: url('../../../public/images/proy_uciplus/fondo_nar.jpg');
    background-attachment: fixed;
    background-size: cover;
    .mok {
      height: 100vh;
      max-width: 600px;
      margin-left: auto;
      position: sticky;
      top:0;
      display: flex;
      justify-content: center;
      align-items: center;
      video {
        max-width: 90%;
        margin-left: auto;
        // box-shadow: ;
        // filter: drop-shadow(-4px 10px 30px rgba(0, 0, 0, 0.5));
        box-shadow: -4px 10px 30px rgba(0, 0, 0, 0.5);
        border-radius: .5em;
        border: .5em solid var(--gris_dark)
      }
    }
    .fondo_nar {
      max-width: 600px;
      margin-right: auto;
      .mundo {
        display: flex;
        flex-flow: column;
        .tit {
          width: auto;
          color: white;
          display: inline-block;
          padding: 1em;
          margin: 40vh 10% 15vh;
          background-image: url(../../../public/images/proy_uciplus/fondo_prop_color.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          text-align: center;
          position: sticky;
          top: 5vh;
          z-index: 10;
        }
        .parr {
          position: relative;
          left: -15%;
          width: 115%;
          padding: 1em 1em 1em 4em;
          margin: 25vh 0;
          color: white;
          background-color: rgba(36,131,57,.8);
          &:last-child {margin-bottom: 50vh;}
          &:after {
            content: '';
            position: absolute;
            display: block;
            top: 0;   
            right: -20vw;
            width: 20vw;
            height: 100%;
            background-color: rgba(36,131,57,.8);
          }
          // background-blend-mode: multiply;
          &:nth-child(2n+1) {
            // margin-right: unset;
            // margin-left: auto;
            background-color: rgba(255, 0, 152, 0.8);
            &:after {background-color: rgba(255, 0, 152, 0.8);}
          }
        }
      }
    }
  }
}
.detalles {
  margin: 15vh auto;
  .fondo_full_page {
    background-color: white;
    height: 90%;
    top: 10%;
  }
  .caja {
    .mockups {
      height: 50vh;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
    }
    .puntos {
      // max-height: 50vh;
      .slider {
        flex: 1;
      }
      .slider-flecha {
        margin: 0;
        min-width: unset;
      }
    }
  }
}
@media (max-width: 599px) {
  .container.case {
    overflow: unset;
  }
  section {
    padding: 0 15px;
  }
  .intro {
    height: 85vh;
    margin-bottom: 15vh;
    .tit {
      font-size: 15vw;
      top: 35vh;
    }
    .mockup_gif {
      width: 95%;
      top: 15vh;
    }
  }
  .resum {
    .subseccion {
      flex-flow: column;
      text-align: center;
      align-items: center;
      .descriptivo {margin-right: 0;}
    }
  }
  .propuesta .propuesta_color {
    width: 100vw;
    margin-left: -15px;
    .mok {
      min-height: 100vh;
      flex-flow: column;
      .tit {
        width: auto;
        color: white;
        display: inline-block;
        padding: 1em;
        margin: -15vh 15% 10vh;
        background-image: url(../../../public/images/proy_uciplus/fondo_prop_color.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
      }
      video {margin-right: auto;}
    }
    .fondo_nar .mundo .parr {
      width: 100%;
      left: 0;
      padding: 1em;
      &:after {display: none;}
    }
  }
  .detalles {
    .tit {margin-bottom: 2em;}
    .fondo_full_page {top: 15%;}
    .caja {
      min-height: 65vh;
    }
  }
}
</style>
