<template>
<div class="home" :style="{background: bg_accent}">
  <div class="page intro center" @click="pabajo('.contexto')">
    <h3 class="tit">
      <span v-html="textos[idioma].soyroberto + años_diseño + textos[idioma].soyroberto1"></span><br>
      <em class="tit serif love">{{loves[idioma][love_count]}}</em>
    </h3>
    <img v-if="scroll_arrow" class="scroll_arrow" src="'../../public/images/cursor_arrowDown.svg" />
  </div>
  <div class="page">
    <section class="contexto" ref="bg_accent">
      <p class="bigparr yo">
        <b>{{textos[idioma].mefascina}}</b>
      </p>
      <p class="bigparr yo" v-html="textos[idioma].intentoconocer"></p>
    </section>
    <section class="grid">
      <div class="lines grid grid-gap-md">
        <span class="child col-4@sm"></span>
        <span class="child col-4@sm"></span>
        <span class="child col-4@sm"></span>
      </div>
      <p class="bigparr yo">
        <b>{{textos[idioma].asiheorganizado}}</b>
      </p>
      <ul class="grid grid-gap-md filtros" :style="active_proy ? active_proy.estilos : ''" 
        @click="pabajo('.porfo')" 
        @mouseover="filtros_hover = true"
        @mouseout="filtros_hover = false"
      >
        <span class="filtro_icon" v-if="filtros_locked.length || filtros_hover">
          <svg class="icon" width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5551 26.3335V14.3335L28.7507 4.11122C28.9022 3.95969 29.022 3.77942 29.1029 3.58098C29.1838 3.38255 29.2242 3.16995 29.2218 2.95567V1.44455C29.2218 1.20881 29.1282 0.982713 28.9615 0.816014C28.7948 0.649315 28.5687 0.555664 28.3329 0.555664H1.66623C1.43049 0.555664 1.20439 0.649315 1.03769 0.816014C0.870994 0.982713 0.777344 1.20881 0.777344 1.44455V2.929C0.77751 3.13894 0.819223 3.34678 0.900079 3.54053C0.980935 3.73428 1.09933 3.91011 1.24846 4.05789L11.444 14.4046V23.4712L18.5551 26.3335Z" fill="#12328B"/>
          </svg>
          <span class="datos num">
            {{proyectos.filtrados.length}} proyectos
            <svg class="num_arrow" width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M37.2201 38.885C37.4633 38.7543 37.5545 38.4511 37.4238 38.2079L35.2936 34.244C35.1628 34.0008 34.8597 33.9096 34.6164 34.0403C34.3732 34.171 34.282 34.4742 34.4127 34.7174L36.3063 38.2408L32.7829 40.1344C32.5396 40.2651 32.4484 40.5683 32.5791 40.8115C32.7098 41.0548 33.013 41.146 33.2562 41.0152L37.2201 38.885ZM1.02734 0.435303C0.527863 0.412521 0.527843 0.412966 0.527819 0.413513C0.527804 0.413832 0.527776 0.414482 0.527749 0.415119C0.527694 0.416394 0.527624 0.418077 0.52754 0.420166C0.527372 0.424344 0.527151 0.430145 0.526896 0.437549C0.526385 0.452356 0.525737 0.473575 0.525102 0.501043C0.523833 0.555978 0.522621 0.635917 0.522682 0.739559C0.522803 0.946832 0.528016 1.24898 0.548063 1.63558C0.588152 2.40871 0.687592 3.52018 0.924466 4.88655C1.39817 7.61906 2.42207 11.3741 4.62234 15.4811C9.03044 23.7093 18.1303 33.2942 36.8393 38.9234L37.1274 37.9658C18.657 32.4084 9.77888 22.9887 5.50381 15.0089C3.3625 11.0119 2.36854 7.36208 1.90977 4.71574C1.68041 3.39269 1.58496 2.32124 1.54672 1.5838C1.5276 1.21512 1.52279 0.930073 1.52268 0.738973C1.52263 0.643428 1.52374 0.571385 1.52484 0.524135C1.52538 0.500511 1.52592 0.483087 1.5263 0.472024C1.52649 0.466492 1.52664 0.462551 1.52674 0.460221C1.52678 0.459056 1.52682 0.458294 1.52683 0.457937C1.52684 0.457758 1.52684 0.457796 1.52684 0.457706C1.52684 0.457844 1.52682 0.458084 1.02734 0.435303Z" fill="#12328B"/>
            </svg>
          </span>
        </span>
        <li class="child col-4@sm">
          <p class="filtro bigparr accent" 
            :class="{active: incluyeFiltro('product design')}" 
            @click="filterProyects('product design')"
          >
            product design 
            <span v-if="filtros_locked.includes('product design')"><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5V5Z" fill="#ffffff"/></svg></span>
            <span class="datos num">
              <svg class="num_arrow" width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.04484 22.6923C4.26999 22.8521 4.58212 22.7992 4.742 22.5741L7.34741 18.905C7.50729 18.6799 7.45438 18.3678 7.22923 18.2079C7.00408 18.048 6.69195 18.1009 6.53207 18.3261L4.21615 21.5874L0.95478 19.2715C0.72963 19.1116 0.4175 19.1645 0.257619 19.3897C0.0977383 19.6148 0.15065 19.927 0.3758 20.0868L4.04484 22.6923ZM17.2131 0.927102C17.1262 0.434708 17.1258 0.434768 17.1254 0.434839C17.1252 0.434877 17.1248 0.434959 17.1243 0.435036C17.1235 0.43519 17.1224 0.435388 17.121 0.435632C17.1183 0.436119 17.1147 0.436788 17.1101 0.437644C17.1009 0.439357 17.0878 0.441821 17.0712 0.445086C17.0378 0.451615 16.9898 0.461348 16.9282 0.474683C16.8049 0.501351 16.6272 0.542448 16.4038 0.601166C15.957 0.718558 15.3261 0.906652 14.5807 1.19118C13.0917 1.75955 11.1347 2.71687 9.27482 4.27333C5.52662 7.41004 2.24093 12.927 3.84136 22.3681L4.82729 22.201C3.28644 13.1113 6.4401 7.94955 9.9166 5.04022C11.669 3.57368 13.5207 2.66616 14.9373 2.12544C15.6447 1.85542 16.2409 1.67791 16.6579 1.56834C16.8663 1.51357 17.0298 1.47584 17.1397 1.45206C17.1946 1.44017 17.2362 1.43176 17.2632 1.42647C17.2768 1.42382 17.2867 1.42194 17.2928 1.4208C17.2959 1.42022 17.2981 1.41983 17.2993 1.41962C17.2999 1.41951 17.3002 1.41945 17.3003 1.41943C17.3004 1.41942 17.3003 1.41943 17.3003 1.41943C17.3002 1.41946 17.2999 1.4195 17.2131 0.927102Z" fill="#12328B"/>
              </svg>
              {{proyectos.filtrados.filter(proy => proy.datos.labels.papel.includes('product design')).length}}
            </span>
          </p>
          <p class="filtro bigparr accent" 
            :class="{active: incluyeFiltro('UX')}" 
            @click="filterProyects('UX')"
          >
            UX 
            <span v-if="filtros_locked.includes('UX')"><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5V5Z" fill="#ffffff"/></svg></span>
            <span class="datos num">
              <svg class="num_arrow" width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.04484 22.6923C4.26999 22.8521 4.58212 22.7992 4.742 22.5741L7.34741 18.905C7.50729 18.6799 7.45438 18.3678 7.22923 18.2079C7.00408 18.048 6.69195 18.1009 6.53207 18.3261L4.21615 21.5874L0.95478 19.2715C0.72963 19.1116 0.4175 19.1645 0.257619 19.3897C0.0977383 19.6148 0.15065 19.927 0.3758 20.0868L4.04484 22.6923ZM17.2131 0.927102C17.1262 0.434708 17.1258 0.434768 17.1254 0.434839C17.1252 0.434877 17.1248 0.434959 17.1243 0.435036C17.1235 0.43519 17.1224 0.435388 17.121 0.435632C17.1183 0.436119 17.1147 0.436788 17.1101 0.437644C17.1009 0.439357 17.0878 0.441821 17.0712 0.445086C17.0378 0.451615 16.9898 0.461348 16.9282 0.474683C16.8049 0.501351 16.6272 0.542448 16.4038 0.601166C15.957 0.718558 15.3261 0.906652 14.5807 1.19118C13.0917 1.75955 11.1347 2.71687 9.27482 4.27333C5.52662 7.41004 2.24093 12.927 3.84136 22.3681L4.82729 22.201C3.28644 13.1113 6.4401 7.94955 9.9166 5.04022C11.669 3.57368 13.5207 2.66616 14.9373 2.12544C15.6447 1.85542 16.2409 1.67791 16.6579 1.56834C16.8663 1.51357 17.0298 1.47584 17.1397 1.45206C17.1946 1.44017 17.2362 1.43176 17.2632 1.42647C17.2768 1.42382 17.2867 1.42194 17.2928 1.4208C17.2959 1.42022 17.2981 1.41983 17.2993 1.41962C17.2999 1.41951 17.3002 1.41945 17.3003 1.41943C17.3004 1.41942 17.3003 1.41943 17.3003 1.41943C17.3002 1.41946 17.2999 1.4195 17.2131 0.927102Z" fill="#12328B"/>
              </svg>
              {{proyectos.filtrados.filter(proy => proy.datos.labels.papel.includes('UX')).length}}
            </span>
          </p>
        </li>
        <li class="child col-4@sm">
          <p class="filtro bigparr accent" 
            :class="{active: incluyeFiltro('branding')}" 
            @click="filterProyects('branding')"
          >
            branding 
            <span v-if="filtros_locked.includes('branding')"><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5V5Z" fill="#ffffff"/></svg></span>
            <span class="datos num">
              <svg class="num_arrow" width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.04484 22.6923C4.26999 22.8521 4.58212 22.7992 4.742 22.5741L7.34741 18.905C7.50729 18.6799 7.45438 18.3678 7.22923 18.2079C7.00408 18.048 6.69195 18.1009 6.53207 18.3261L4.21615 21.5874L0.95478 19.2715C0.72963 19.1116 0.4175 19.1645 0.257619 19.3897C0.0977383 19.6148 0.15065 19.927 0.3758 20.0868L4.04484 22.6923ZM17.2131 0.927102C17.1262 0.434708 17.1258 0.434768 17.1254 0.434839C17.1252 0.434877 17.1248 0.434959 17.1243 0.435036C17.1235 0.43519 17.1224 0.435388 17.121 0.435632C17.1183 0.436119 17.1147 0.436788 17.1101 0.437644C17.1009 0.439357 17.0878 0.441821 17.0712 0.445086C17.0378 0.451615 16.9898 0.461348 16.9282 0.474683C16.8049 0.501351 16.6272 0.542448 16.4038 0.601166C15.957 0.718558 15.3261 0.906652 14.5807 1.19118C13.0917 1.75955 11.1347 2.71687 9.27482 4.27333C5.52662 7.41004 2.24093 12.927 3.84136 22.3681L4.82729 22.201C3.28644 13.1113 6.4401 7.94955 9.9166 5.04022C11.669 3.57368 13.5207 2.66616 14.9373 2.12544C15.6447 1.85542 16.2409 1.67791 16.6579 1.56834C16.8663 1.51357 17.0298 1.47584 17.1397 1.45206C17.1946 1.44017 17.2362 1.43176 17.2632 1.42647C17.2768 1.42382 17.2867 1.42194 17.2928 1.4208C17.2959 1.42022 17.2981 1.41983 17.2993 1.41962C17.2999 1.41951 17.3002 1.41945 17.3003 1.41943C17.3004 1.41942 17.3003 1.41943 17.3003 1.41943C17.3002 1.41946 17.2999 1.4195 17.2131 0.927102Z" fill="#12328B"/>
              </svg>
              {{proyectos.filtrados.filter(proy => proy.datos.labels.papel.includes('branding')).length}}
            </span>
          </p>
          <p class="filtro bigparr accent" 
            :class="{active: incluyeFiltro('UI')}" 
            @click="filterProyects('UI')"
          >
            UI
            <span v-if="filtros_locked.includes('UI')"><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5V5Z" fill="#ffffff"/></svg></span>
            <span class="datos num">
              <svg class="num_arrow" width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.04484 22.6923C4.26999 22.8521 4.58212 22.7992 4.742 22.5741L7.34741 18.905C7.50729 18.6799 7.45438 18.3678 7.22923 18.2079C7.00408 18.048 6.69195 18.1009 6.53207 18.3261L4.21615 21.5874L0.95478 19.2715C0.72963 19.1116 0.4175 19.1645 0.257619 19.3897C0.0977383 19.6148 0.15065 19.927 0.3758 20.0868L4.04484 22.6923ZM17.2131 0.927102C17.1262 0.434708 17.1258 0.434768 17.1254 0.434839C17.1252 0.434877 17.1248 0.434959 17.1243 0.435036C17.1235 0.43519 17.1224 0.435388 17.121 0.435632C17.1183 0.436119 17.1147 0.436788 17.1101 0.437644C17.1009 0.439357 17.0878 0.441821 17.0712 0.445086C17.0378 0.451615 16.9898 0.461348 16.9282 0.474683C16.8049 0.501351 16.6272 0.542448 16.4038 0.601166C15.957 0.718558 15.3261 0.906652 14.5807 1.19118C13.0917 1.75955 11.1347 2.71687 9.27482 4.27333C5.52662 7.41004 2.24093 12.927 3.84136 22.3681L4.82729 22.201C3.28644 13.1113 6.4401 7.94955 9.9166 5.04022C11.669 3.57368 13.5207 2.66616 14.9373 2.12544C15.6447 1.85542 16.2409 1.67791 16.6579 1.56834C16.8663 1.51357 17.0298 1.47584 17.1397 1.45206C17.1946 1.44017 17.2362 1.43176 17.2632 1.42647C17.2768 1.42382 17.2867 1.42194 17.2928 1.4208C17.2959 1.42022 17.2981 1.41983 17.2993 1.41962C17.2999 1.41951 17.3002 1.41945 17.3003 1.41943C17.3004 1.41942 17.3003 1.41943 17.3003 1.41943C17.3002 1.41946 17.2999 1.4195 17.2131 0.927102Z" fill="#12328B"/>
              </svg>
              {{proyectos.filtrados.filter(proy => proy.datos.labels.papel.includes('UI')).length}}
            </span>
          </p>
        </li>
        <li class="child col-4@sm">
          <p class="filtro bigparr accent" 
            :class="{active: incluyeFiltro('front-end')}" 
            @click="filterProyects('front-end')"
          >
            front-end 
            <span v-if="filtros_locked.includes('front-end')"><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5V5Z" fill="#ffffff"/></svg></span>
            <span class="datos num">
              <svg class="num_arrow" width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.04484 22.6923C4.26999 22.8521 4.58212 22.7992 4.742 22.5741L7.34741 18.905C7.50729 18.6799 7.45438 18.3678 7.22923 18.2079C7.00408 18.048 6.69195 18.1009 6.53207 18.3261L4.21615 21.5874L0.95478 19.2715C0.72963 19.1116 0.4175 19.1645 0.257619 19.3897C0.0977383 19.6148 0.15065 19.927 0.3758 20.0868L4.04484 22.6923ZM17.2131 0.927102C17.1262 0.434708 17.1258 0.434768 17.1254 0.434839C17.1252 0.434877 17.1248 0.434959 17.1243 0.435036C17.1235 0.43519 17.1224 0.435388 17.121 0.435632C17.1183 0.436119 17.1147 0.436788 17.1101 0.437644C17.1009 0.439357 17.0878 0.441821 17.0712 0.445086C17.0378 0.451615 16.9898 0.461348 16.9282 0.474683C16.8049 0.501351 16.6272 0.542448 16.4038 0.601166C15.957 0.718558 15.3261 0.906652 14.5807 1.19118C13.0917 1.75955 11.1347 2.71687 9.27482 4.27333C5.52662 7.41004 2.24093 12.927 3.84136 22.3681L4.82729 22.201C3.28644 13.1113 6.4401 7.94955 9.9166 5.04022C11.669 3.57368 13.5207 2.66616 14.9373 2.12544C15.6447 1.85542 16.2409 1.67791 16.6579 1.56834C16.8663 1.51357 17.0298 1.47584 17.1397 1.45206C17.1946 1.44017 17.2362 1.43176 17.2632 1.42647C17.2768 1.42382 17.2867 1.42194 17.2928 1.4208C17.2959 1.42022 17.2981 1.41983 17.2993 1.41962C17.2999 1.41951 17.3002 1.41945 17.3003 1.41943C17.3004 1.41942 17.3003 1.41943 17.3003 1.41943C17.3002 1.41946 17.2999 1.4195 17.2131 0.927102Z" fill="#12328B"/>
              </svg>
              {{proyectos.filtrados.filter(proy => proy.datos.labels.papel.includes('front-end')).length}}
            </span>
          </p>
          <p class="filtro bigparr accent" 
            :class="{active: incluyeFiltro('back-end')}" 
            @click="filterProyects('back-end')"
          >
            back-end 
            <span v-if="filtros_locked.includes('back-end')"><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 10C16 8.897 15.103 8 14 8H13V5C13 2.243 10.757 0 8 0C5.243 0 3 2.243 3 5V8H2C0.897 8 0 8.897 0 10V18C0 19.103 0.897 20 2 20H14C15.103 20 16 19.103 16 18V10ZM5 5C5 3.346 6.346 2 8 2C9.654 2 11 3.346 11 5V8H5V5Z" fill="#ffffff"/></svg></span>
            <span class="datos num">
              <svg class="num_arrow" width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.04484 22.6923C4.26999 22.8521 4.58212 22.7992 4.742 22.5741L7.34741 18.905C7.50729 18.6799 7.45438 18.3678 7.22923 18.2079C7.00408 18.048 6.69195 18.1009 6.53207 18.3261L4.21615 21.5874L0.95478 19.2715C0.72963 19.1116 0.4175 19.1645 0.257619 19.3897C0.0977383 19.6148 0.15065 19.927 0.3758 20.0868L4.04484 22.6923ZM17.2131 0.927102C17.1262 0.434708 17.1258 0.434768 17.1254 0.434839C17.1252 0.434877 17.1248 0.434959 17.1243 0.435036C17.1235 0.43519 17.1224 0.435388 17.121 0.435632C17.1183 0.436119 17.1147 0.436788 17.1101 0.437644C17.1009 0.439357 17.0878 0.441821 17.0712 0.445086C17.0378 0.451615 16.9898 0.461348 16.9282 0.474683C16.8049 0.501351 16.6272 0.542448 16.4038 0.601166C15.957 0.718558 15.3261 0.906652 14.5807 1.19118C13.0917 1.75955 11.1347 2.71687 9.27482 4.27333C5.52662 7.41004 2.24093 12.927 3.84136 22.3681L4.82729 22.201C3.28644 13.1113 6.4401 7.94955 9.9166 5.04022C11.669 3.57368 13.5207 2.66616 14.9373 2.12544C15.6447 1.85542 16.2409 1.67791 16.6579 1.56834C16.8663 1.51357 17.0298 1.47584 17.1397 1.45206C17.1946 1.44017 17.2362 1.43176 17.2632 1.42647C17.2768 1.42382 17.2867 1.42194 17.2928 1.4208C17.2959 1.42022 17.2981 1.41983 17.2993 1.41962C17.2999 1.41951 17.3002 1.41945 17.3003 1.41943C17.3004 1.41942 17.3003 1.41943 17.3003 1.41943C17.3002 1.41946 17.2999 1.4195 17.2131 0.927102Z" fill="#12328B"/>
              </svg>
              {{proyectos.filtrados.filter(proy => proy.datos.labels.papel.includes('back-end')).length}}
            </span>
          </p>
        </li>
      </ul>
      <small class="parr yo">*{{textos[idioma].cadaproyectotiene}}</small>
      <porfolio
        class="porfo"
        :currentScrollPosition="currentScrollPosition"
        :filtros_activos="filtros_activos"
        :filtros_on="filtros_locked.length"
        @active_proy="active_proy = $event"
        @proyectos="proyectos = $event"
      />
    </section>
    <section>
      <futer />
    </section>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import porfolio from "@/components/porfolio.vue";
import futer from "@/components/futer.vue";

export default {
  name: 'Home',
  components: {porfolio, futer},
  props: {
    currentScrollPosition: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['idioma'])
  },
  data() {
    return {
      active_proy: false,
      años_diseño: 6,
      filter_color: 'var(--color_accent)',
      filtros_activos: ['product design', 'UX', 'branding', 'UI', 'front-end', 'back-end'],
      filtros_locked: [],
      filtros_hover: false,
      scroll_arrow: false,
      bg_accent: '',
      love: '',
      love_count: 0,
      loves: {
        es: [
          "crear",
          "colaborar",
          "entender",
          "diverger",
          "ponerle mimo a lo que hago",
          "probar ideas",
          "aprender"
          // "buscar rutas alternativas",
        ],
        en: [
          "to create",
          "to collaborate",
          "understand",
          "to diverge",
          "put care into what I do",
          "to try things out",
          "to learn and try again"
          // "to find alternative routes",
          // "mission-oriented projects",
        ]
      },
      textos: {
        es: {
          soyroberto: 'Soy Roberto Mesejo<br>llevo <b>trabajando en productos digitales desde hace más de ',
          soyroberto1: ' años</b><br>y me encanta',
          mefascina: 'Me fascina el proceso creativo.',
          intentoconocer: '<b>Intento conocer e involucrarme en todas las etapas de creación de un producto</b> (entender mejor cómo funcionan y se conectan las piezas me parece muy útil para intentar resolver el puzzle)',
          asiheorganizado: 'y por eso he organizado mis proyectos según la fase en la que he intervenido:',
          cadaproyectotiene: 'Para dar más contexto, cada proyecto tiene tambien etiquetas según para quién lo he realizado (estudios, empresas, como freelance o emprendedor) y el producto final (plataformas, webs y apps)'
        }, 
        en: {
          soyroberto: "I'm Roberto Mesejo,<br>I've been <b>working in building digitals products for more than ",
          soyroberto1: " years</b><br>and I love",
          mefascina: 'I am fascinated by the creative process.',
          intentoconocer: '<b>I like getting involved in all of the phases for creating a product</b> so I can have a holistic view of the project.',
          asiheorganizado: "That's why I've arranged the projects by the phase in which I have intervened:",
          cadaproyectotiene: 'Each project also has other labels for context: who I made it for (companies, studies, as freelance, or as entrepeneur) and the final product (platforms, webs and apps)'
        }
      },
      proyectos: {todos: [], filtrados: []}
    }
  },
  watch: {
    currentScrollPosition() {
      if (this.currentScrollPosition > (this.$refs.bg_accent.offsetTop) ) {
        if (!this.bg_accent) this.bg_accent = 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 16.24%), #FBF6F1';
      } else {
        this.bg_accent = '';
      }
    }
  },
  mounted: function() {
    let that = this;
    setInterval(() => {
      that.love_count < (that.loves[this.idioma].length - 1) ? that.love_count++ : that.love_count = 0;
    }, 2000);

    if (window.innerWidth < 600) setTimeout(() => this.scroll_arrow = true, 4000);
    
    this.años_diseño = (new Date().getFullYear() - 2015) || 6;
  },
  methods: {
    incluyeFiltro(filtro) {
      return (this.active_proy && this.active_proy.datos.labels.papel.includes(filtro)) || this.filtros_locked.includes(filtro);
    },
    filterProyects(filtro_a_activar) {
      //empiezan todos "activos", 
      if (!this.filtros_locked.length) this.filtros_activos = [];

      //filtrar proyectos con este filtro
      if (!this.filtros_activos.includes(filtro_a_activar)) {
        this.filtros_activos.push(filtro_a_activar);
      } else {
        this.filtros_activos.splice(this.filtros_activos.indexOf(filtro_a_activar), 1);
      }

      //añadir a lista para mostrar candadito
      if (!this.filtros_locked.includes(filtro_a_activar)) this.filtros_locked.push(filtro_a_activar)
      else {this.filtros_locked.splice(this.filtros_locked.indexOf(filtro_a_activar), 1)}

      if (!this.filtros_locked.length) this.filtros_activos = ['product design', 'UX', 'branding', 'UI', 'front-end', 'back-end'];
    },
    pabajo(destino) {
      let destino_dom = document.querySelector(destino);
      if ((this.currentScrollPosition < (destino_dom.offsetTop + 500)) || (this.currentScrollPosition < (destino_dom.offsetParent.offsetTop + 500))) {
        destino_dom.scrollIntoView({ behavior: 'smooth' });
      } 
    }
  }
}
</script>
<style scoped lang="scss">
.home {
  transition: background 1s ease-in;
  .page.intro {
    position: relative;
    margin-bottom: -20vh;
    cursor: url("../../public/images/cursor_arrowDown.svg"), pointer;
    .tit {
      text-align: center;
      max-width: 100%;
      overflow: hidden;
      font-weight: normal;
    }
    .love {
      display: inline-block;
      margin-top: .3em;
      position: relative;
      animation: 2s infinite ease-out slidein;
      color: var(--color_accent);
      font-weight: bold;
      font-size: 1.1em;
    }
    .scroll_arrow {
      position: absolute;
      top: 94vh;
      left: 50%;
      height: 4vh;
      transform: translateX(-50%);
    }
  }
  section {
    padding: 30vh 0;
    &.grid {
      position: relative;
      width: 100%;
      height: auto;
      .lines.grid {
        position: absolute;
        width: 100%;
        height: 100%;
        span.child {
          border-right: 1px solid var(--color_accent);
          border-left: 1px solid var(--color_accent);
          opacity: .1;
        }
      }
    }
    .filtros {
      position: sticky;
      top: 5vh;
      margin-top: 5vh;
      margin-bottom: 5vh;
      z-index: 15;
      --color_proyecto: var(--color_accent);
      .filtro_icon {
        position: absolute;
        left: -3em;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        .icon path {fill: var(--color_accent);}
        .num {
          position: absolute;
          top: -2em;
          left: -2em;
          width: max-content;
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          color: var(--color_accent);
          .num_arrow {
            margin-left: .2em;
          }
        }
      }
      &:hover .child .filtro .num {
        display: unset;
        position: absolute;
        top: -2.5em;
        right: -.5em;
        width: max-content;
        display: flex;
        align-items: flex-end;
        color: var(--color_accent);
        .num_arrow {
          position: relative;
          top: 0.2em;
        }
      }
      .child {
        display: flex;
        justify-content: space-between;
        .filtro {
          position: relative;
          transition: all .2s ease-in;
          font-size: clamp(1.3rem, 2.2vw, 1.8rem);
          white-space: nowrap;
          padding: .1em .3em;
          border: 1px solid var(--color_proyecto);
          color: var(--color_proyecto);
          margin-bottom: 0;
          .num {display: none;}
          &:hover {
            cursor: pointer;
            &:not(.active):after {
              content: '';
              background: no-repeat url('../../public/images/icon_lock2.svg');
              position: absolute;
              left: calc(100% - .2em);
              top: 0;
              height: 100%;
              width: 3em;
            }
            // .num {
            //   display: unset;
            //   position: absolute;
            //   top: -2.5em;
            //   right: -1em;
            //   width: max-content;
            //   display: flex;
            //   align-items: flex-end;
            //   color: var(--color_accent);
            //   .num_arrow {
            //     position: relative;
            //     top: 0.2em;
            //   }
            // }
          }
          &.active {
            background-color: var(--color_proyecto);
            color: var(--color_base_light);
          }
          &.locked:after {
            content: '';
            background: no-repeat url('../../public/images/icon_lock.svg');
            height: 100%;
            top: 0;
            width: 3em;
          }
        }
      }
    }
  }
  @media (max-width: 599px) {
    section .filtros {
      position: unset;
    }
  }
}
</style>
