<template>
  <div class="case">
    <section class="intro">
      <div class="fondo_full_page"></div>
      <volver />
      <h1 class="tit">
        
        Relocation*
      </h1>
      <img class="mockup" src="images/proy_steps_mockup.png" />
    </section>
    <section class="resum">
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].objetivo}}</p>
        <h3 class="subtit">{{textos[idioma].redefinir}}</h3>
      </div>
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].labor}}</p>
        <div class="res_icons">
          <p class="datos">
            <b>{{textos[idioma].partedelequipo}}</b> <small>.fig</small>
          </p>
          <p class="datos">
            <b>Front-end Lead</b> <small>.vue</small>
          </p>
        </div>
      </div>
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].donde}}</p>
        <p class="parr donde">
          <span><b>2020</b></span><br>
          <span><b>Estudio143</b></span>
        </p>
      </div>
    </section>
    <section class="contexto">
      <h3 class="tit">{{textos[idioma].dequevaesto}}</h3>
      <p class="parr" v-html="textos[idioma].noscontrato"></p>
      <div class="problema row">
        <h3 class="subtit">{{textos[idioma].problema}}</h3>
        <p class="parr">{{textos[idioma].estabantrabajando}}</p>
      </div>
    </section>
    <section class="solucion">
      <h3 class="tit">{{textos[idioma].quehicimos}}</h3>
      <p class="parr" v-html="textos[idioma].eldiseñohasido"></p>
      <p class="parr">{{textos[idioma].esteesunpequeño}}</p>
      <div class="row ux">
        <h3 class="subtit">{{textos[idioma].diseño}}</h3>
        <article>
          <p class="parr" v-html="textos[idioma].entenderelproblema"></p>
          <p class="parr" v-html="textos[idioma].visitamosvarias"></p>
          <p class="parr" v-html="textos[idioma].tambienhicimos"></p>
        </article>
        <article>
          <p class="parr" v-html="textos[idioma].filtrarorganizar"></p>
          <p class="parr" v-html="textos[idioma].organizamostodo"></p>
        </article>
        <article>
          <p class="parr" v-html="textos[idioma].ui"></p>
          <p class="parr" v-html="textos[idioma].yaentramosen"></p>
        </article>
      </div>
      <div class="row ux">
        <h3 class="subtit">DEVELOPMENT</h3>
        <p class="parr" v-html="textos[idioma].conelprototipo"></p>
        <p class="parr" v-html="textos[idioma].eldesarrollo"></p>
        <p class="parr" v-html="textos[idioma].paraelback"></p>
      </div>
    </section>
    <section class="detalles">
      <h3 class="tit">{{textos[idioma].productofinal}}</h3>
      <p class="parr" v-html="textos[idioma].osdejoalgunas"></p>
      <div class="img_cont">
        <div class="imagenes">
          <img class="mockup" src="/images/proy_steps/steps_2.jpg" />
          <img class="mockup" src="/images/proy_steps/steps_1.jpg" />
          <img class="mockup" src="/images/proy_steps/steps_3.jpg" />
        </div>
        <span class="sombra"></span>
      </div>
    </section>
    <futer_cases />
  </div>
</template>

<script>
import futer_cases from "@/components/futer_cases.vue"
import volver from "@/components/volver.vue"
import { mapState } from 'vuex'

export default {
  name: 'steps',
  components: {futer_cases, volver},
  props: {},
  data() {
    return {
      textos: {
        es: {
          volver: 'volver',
          objetivo: 'Objetivo:',
          redefinir: 'Redefinir una herramienta para optimizar el flujo y calidad del trabajo de una empresa',
          donde: 'Dónde y cuando:',
          labor: 'Labor en el proyecto:',
          partedelequipo: 'Parte del equipo de diseño UX',
          dequevaesto: '¿DE QUÉ VA ESTO?',
          noscontrato: 'Nos contrató una empresa dedicada a la recolocación de altos ejecutivos de grandes empresas (cuando los cambian de país) para rehacer su panel de trabajo que les estaba volviendo locos. <br>\
                        La empresa ofrece una gran cantidad de servicios de distinta índole para los que necesitan gestionar datos del cliente, archivos, establecer citas, proponer viviendas, etc.<br>',
          problema: 'PROBLEMA',
          estabantrabajando: 'Estaban trabajando con una herramienta que hacía que los procesos fueran laaargos y tediosos. Querían reducir errores que se producían, y poder tener una visión global de todos los datos e interacciones.',
          quehicimos: '¿QUÉ HICIMOS?',
          eldiseñohasido: 'El diseño ha sido un proceso bastante complejo.<br>\
                          Necesitábamos entender cómo y para qué cada tipo de usuario tenía que usar la plataforma, habiendo muchos stakeholders con perfiles y necesidades muy distintas a los que no teníamos acceso para entrevistarles.',
          esteesunpequeño: 'Este es un pequeño resumen de cómo lo hicimos:',
          diseño: 'DISEÑO',
          entenderelproblema: '<b>1. Entender el problema</b><br><i>Entrevistas y shadowing</i>',
          visitamosvarias: 'Visitamos varias veces al cliente para ver de primera mano cómo trabajan, cómo es su día a día. El objetivo era acercanos de una forma real a la acción para poder sacar <i>User Flows</i> y <i>Pain Points</i> reales y en contexto, y entender bien para quién teníamos que diseñar la plataforma.',
          tambienhicimos: 'También hicimos ejercicios de ideación para filtrar prioridades y descubrir nuevas áreas en las que poder trabajar para mejorar su experiencia con la plataforma.',
          filtrarorganizar: '<b>2. Filtrar, organizar y esquematizar todo lo observado</b><br><i>User Personas, User Flows y primeros Wireframes</i>',
          organizamostodo: "Organizamos todo lo aprendido y establecemos la idea general de lo que creemos debería ser la plataforma, desde roles y actores implicados hasta funcionalidades y características. <br>\
                            Con eso les explicamos <i>a grosso modo</i> la idea que tenemos con la ayuda de <i>Wire-Flows</i>, cómo la ejecutaremos y un planning del proyecto.",
          ui: "<b>3. UI</b><br><i>Sistema de diseño y Prototipo</i>",
          yaentramosen: "Ya entramos en el producto final. Creamos un pequeño sistema de diseño separando todos los componentes que tendrá la plataforma (esto agiliza muchísimo el desarrollo), montamos todas las pantallas y realizamos en figma un prototipo navegable de la plataforma casi completa. Lo validamos con el cliente y reajustamos en función de lo que sale en la reunión.",
          conelprototipo: "Con el prototipo aprobado hicimos un backlog de tareas de back y front y fuimos trabajando en Sprints de 2 semanas.",
          eldesarrollo: "El desarrollo consistió en un front-end desarrollado desde cero con Vue.js, usando las mínimas dependencias posibles, que al final fue poco más que algunos componentes de Vuetify (sobretodo para la gestión de formularios y el calendario).",
          paraelback: "Para el back se desarrolló un API Rest para la conexión con la base de datos mediante web-services.",
          productofinal: 'PRODUCTO FINAL',
          osdejoalgunas: 'Os dejo algunas capturas de pantalla para que veáis cómo quedó la plataforma:<br><i>*son temporales, para que os hagáis una idea mientras lo monto mejor</i>'
        },
        en: {
          volver: 'go back',
          objetivo: 'Goal:',
          redefinir: 'Redefine the tool to optimize all the flows and improve the quality of their job',
          donde: 'When and where:',
          labor: 'My role:',
          partedelequipo: 'UX Design team',
          dequevaesto: 'WHAT IS THIS ABOUT?',
          noscontrato: "We were hired by a company dedicated to the relocation of Hi-Level execs. They needed help because the tool they used to manage all their work was driving them crazy. <br>\
                        They offer a wide variety of services for which they need to handle lots of client data, archives, book appointments, propose houses, etc.<br>",
          problema: 'PROBLEM',
          estabantrabajando: 'They were working with a tool that made all processes to be looong and tedious. They needed to minimize errors and be able to have an eagle view of all the data and interactions.',
          quehicimos: 'WHAT DID WE DO?',
          eldiseñohasido: "The design has been a pretty complex process.<br>\
                          We needed to understand how and for what purposes each kind of user had to use the platform, having many stakeholders with completely different profiles and needs, to which we didn't have access to interview them.",
          esteesunpequeño: 'This is a little summary of how we approach it:',
          diseño: 'DESIGN',
          entenderelproblema: '<b>1. Understand the problem</b><br><i>Group interviews and shadowing</i>',
          visitamosvarias: "We visited the client several times to watch first-person how did they work, how was their day-to-day life. Our goal here was to get the closest to the real thing, get to know how the sausage was made, to get as accurate as possible <i>User Flows</i> and <i>Pain Points</i> that were also in context, and have a good understanding of who we were designing for.",
          tambienhicimos: "We also made ideation exercises to filter priorities and discover new areas of work to improve their experience with the platform.",
          filtrarorganizar: '<b>2. Filter, organize and map all the info we gather</b><br><i>User Personas, User Flows and first Wireframes</i>',
          organizamostodo: "We organized all the learnings and establish the general idea of what we believed should be the platform, from roles and actors involved to functionalities and characteristics. <br>\
                            With that in hand, we explained to them <i>a grosso modo</i> the idea with the help of <i>Wire-Flows</i>, how we were gonna execute it and a detailed roadmap.",
          ui: "<b>3. UI</b><br><i>Design system and Prototype</i>",
          yaentramosen: "We started to focus on the final product. We created a Design System separating all the components that we were gonna use (this will accelerate incredibly the development), created all the screens and states, and made a prototype in Figma of nearly the whole platform. We validated it with the client and made final readjustments with their feedback.",
          conelprototipo: "With the prototype approved, we made a backlog of all the front-end and back-end tasks and we worked in 2-week sprints.",
          eldesarrollo: "The development consisted of a Front-end developed from zero with Vue.js, we used the minimum dependencies, which at the end were mostly Vuetify components for the forms and calendar.",
          paraelback: "As for the Back-end, we created an API REST for handling the connection with the database using web-services.",
          productofinal: 'FINAL PRODUCT',
          osdejoalgunas: 'Here I leave some screenshots for you to see how the platform ended:<br><i>*they are temporary, to get a better idea until I have the time to build prettier mockups</i>'
        }
      }
    }
  },
  watch: {
  },
  computed: {
    ...mapState(['idioma'])
  },
  methods: {
  },
  mounted: function() {}
}
</script>

<style scoped lang="scss">
.case {
  --case_primario: #A291D6;
  --case_fondo: #EFEFFE;
  --case_textos: #373737;

  --font_parr_sans: 'Nunito', Helvetica, Arial, sans-serif;

  ::selection {background-color: var(--case_primario); color: white;}
  .tit {
    font-family: var(--font_parr_sans);
	}
	.parr-grande {
    font-family: "Calluna", "Times New Roman", Times, serif;
		line-height: 1.5em;
	}
}
section {
  position: relative;
  max-width: 90ch;
  margin: 0 auto 20vh;
  .subtit {
    margin-bottom: .5em;
    color: var(--case_primario);
  }
  article {
    margin-bottom: 2em; 
    .subtit {font-size: .5em;}
  }
}
.intro {
  width: 100%;
  height: 100vh;
  padding-top: 35vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  .fondo_full_page {
    height: 80%;
    top: 0;
    z-index: -1;
    background-color: var(--case_fondo);
  }
  .tit {
    color: white;
    position: fixed;
    top: 25vh;
    font-size: 7vw;
    margin-top: 0;
  }
  .mockup {
    margin-top: 5em;
    z-index: 2;
    max-width: 80%;
  }
}
.resum {
  margin: 10vh auto 15vh;
  .subseccion {
    display: flex;
    margin: 1em auto;
    .descriptivo {width: 12em;}
    .res_icons p {font-family: var(--font_parr_sans);}
  }
}
.detalles {
  .img_cont {
    position: relative;
    min-width: 100ch;
    width: 130%;
    cursor: url("../../../public/images/cursor_arrowRight.svg"), e-resize;
    .imagenes {
      display: flex;
      align-items: center;
      overflow-x: scroll;
      width: 100%;
      .mockup {
        width: 35rem;
        margin-top: 2rem;
        margin-right: 2rem;
        box-shadow: 0 -1px 5px rgba(0,0,0,.05);
        &:last-child {margin-right: 10%!important;}
      }
    }
    .sombra {
      content: '';
      position: absolute;
      width: 10%;
      height: 110%;
      top: -5%;
      right: 0;
      overflow-x: none;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      // box-shadow: inset -19px 6px 35px -12px red;
      z-index: 1;
    }
  }
}

@media (max-width: 599px) {
  .container.case {
    overflow: unset;
  }
  section {
    padding: 0 15px;
  }
  .intro {
    height: 85vh;
    margin-bottom: 15vh;
    .tit {
      font-size: 15vw;
      top: 25vh;
      padding: 0 15px;
      line-height: 1em;
    }
    .mockup_gif {
      width: 95%;
      top: 15vh;
    }
  }
  .resum {
    .subseccion {
      flex-flow: column;
      text-align: center;
      align-items: center;
      margin-top: 2em;
      .descriptivo {margin-right: 0;}
    }
  }
  .detalles {
    .img_cont {
      min-width: unset;
      width: calc(100% + 15px);
      .imagenes .mockup {width: 80vw;}
    }
  }
}
</style>
