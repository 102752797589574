import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//General styles
import  "./css/global.scss"

createApp(App)
.use(store)
.use(router)
.mount('#app')
