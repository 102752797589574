<template>
	<div class="case">
		<div class="container">
			<div class="app_mockup">
				<img class="mockup_in" src="images/proy_abuelapp_mockup.gif" />
			</div>
				<section class="diapo front">
					<div>
						<h3 class="subtit">una abuela, una app,<br>y un meteorólogo<br>entran en un bar y... </h3>
						<p class="datos">
							Si te dan igual la presión atmosférica, el porcentaje de nubosidad o la medición galvanómica, y lo único que
							quieres saber si salir de casa con paraguas o no...
						</p>
						<!-- <a href="../abuelapp_2.html" target="blank"><img src="../images/abuelapp/enlace.png"></a> -->
					</div>
				</section>
				<section class="diapo funciona intro">
					<h3 class="subtit">
						Receta para conseguir<br>
						un poco de sabiduría abuelil
					</h3>
					<p class="parr">
						Queremos que esta app sea como <b>una abuela virtual</b> (Appuela 😉) que,<b><br>
						usando datos metereológicos</b> (algo menos certeros que el “supersentido abuelil”, pero bastante fiables), <b>te aconseje como lo haría ella</b>.
					</p>
				</section>
				<section class="diapo funciona titulo">
					<h3 class="parr">
						sólo hay que seguir<br>
						esta historia simple
					</h3>
				</section>
				<section class="diapo funciona historia pri active">
					<p class="parr">
						<span>uno</span>
						Vas a salir de casa, y, como de costumbre, te despides de tu abuela al grito de: “Adiós abuela”.
					</p>
				</section>
				<section class="diapo funciona historia sec">
					<p class="parr">
						<span>dos</span>
						La abres, y cuando ya estas casi fuera escuchas la famosa frase “¿PEERO VAS A SALIR ASÍ?”
					</p>
				</section>
				<section class="diapo funciona historia ter">
					<p class="parr">
						<span>tres</span>
						Te miras de arriba abajo, buscando algo raro o excepcional. Levantas la cabeza y la tienes justo delante de
						tus narices, con el paraguas en la mano y, mirándote con cara de infinita sabiduría, te dice “Anda, llévate
						el paraguas, que en abril, aguas mil”.
					</p>
				</section>
		</div>
		<futer_cases />
	</div>
</template>

<script>
	import futer_cases from "@/components/futer_cases.vue"
	import {
		mapState
	} from 'vuex'

	export default {
		name: 'abuelapp',
		components: {
			futer_cases
		},
		data() {
			return {
				textos: {
					es: {},
					en: {}
				}
			}
		},
		watch: {},
		computed: {
			...mapState(['idioma'])
		},
		methods: {},
		mounted: function () {}
	}
</script>

<style scoped lang="scss">
	.case {
		--case_primario: #87BBA1;
		--case_fondo: #B8D8BA;
		--case_textos: #364958;

		--font_parr_sans: 'Gotham', Helvetica, Arial, sans-serif;

		::selection {
			background-color: var(--case_primario);
		}
		.tit, .subtit, .parr, .datos {
			font-family: var(--font_parr_sans);
			color: var(--case_textos);
		}
		.parr, .subtit, .datos {
			color: var(--case_textos);
		}
		background-color: var(--case_fondo);
		background-image: url('../../../public/images/proy_abuelapp_mancha.jpg');
		.container {
			position: relative;
			max-height: 100vh;
			max-width: 170ch;
			margin: 0 auto;
			overflow-y: scroll;
			scroll-snap-type: y proximity;
			scroll-padding: 1.5rem;
			margin-bottom: 20vh;
			.app_mockup {
				position: fixed;
				width: 50%;
				max-width: 80ch;
				height: 100vh;
				display: flex;
				justify-content: center;
				align-items: center;
				.mockup_in {
					height: clamp(450px, 60%, 600px);
					filter: drop-shadow(0px 2px 15px #87BBA1);
				}
			}
			.diapo {
				position: relative;
				width: 100%;
				padding: 2em 5em;
				min-height: 60vh;
				padding-left: 42%;
				scroll-snap-align: center;
				scroll-snap-stop: always;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-flow: column;
				&.front {
					height: 90vh;
					.subtit {
						font-weight: bold;
						font-size: clamp(20px, 3vw, 35px);
						line-height: 120%;
						margin-bottom: .5em;
					}
					.datos {font-weight: 200;}
				}
				&.intro {
					.subtit {
						text-align: right;
						margin-left: auto;
						margin-bottom: 1em;
					}
				}
			}
		}
	}

	@media (max-width: 599px) {
		.container.case {
			overflow: unset;
		}

		section {
			padding: 0 15px;
		}
	}
</style>