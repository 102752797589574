<template>
  <div class="porfolio">
    <div class="proyectos">
      <proyecto v-for="(proyecto, index) in proyectos_filtered" :key="index"
        :id="proyecto.nombre" 
        :datos_proy="proyecto"
        :hover="proyecto.active"
        @click="proyecto.active = !proyecto.active"
      />
    </div>
  </div>
</template>

<script>
import proyecto from "@/components/proyecto.vue";

export default {
  name: 'porfolio',
  components: {proyecto},
  props: {
    currentScrollPosition: {
      type: Number,
      default: 0
    },
    filtros_activos: {
      type: Array,
      default: () => []
    },
    filtros_on: {
      default: false
    }
  },
  data() {
    return {
      proyectos: [
        //weclapp
        {
          nombre: 'weclapp',
          nombre_logo: 'weclapp',
          active: false,
          datos: {
            subtit: {
              es: '¿Cómo llegar a petarlo siendo músico?',
              en: 'How to succeed as a musician by playing shows?'
            },
            labels: {
              papel: [
                'UX', 
                'UI', 
                'product design',
                'branding',
                'front-end',
                'back-end'
              ],
              tipo_cliente: {
                es: [
                  'Emprendedor', 
                  'Start-up'
                ],
                en: [
                  'Entrepeneur', 
                  'Start-up'
                ]
              }
            }
          },
          descripcion: {
            es: 'Plataforma y comunidad con la que pretendemos dar superpoderes a los músicos para que lo tengan más fácil para crecer y tocar más.',
            en: 'Platform and community aiming for giving superpowers to musicians to make them easier to grow and play more.'
          },
          images: {
            // logo: 'images/proy_weclapp_logo.svg',
            logo_alt: 'weclapp.live, aplaude el talento',
            mockup: 'images/proy_weclapp_mockup.png'
          },
          estilos: '--color_proyecto: #FB5130;\
                    --fondo_proyecto: no-repeat url("/images/proy_weclapp_mancha.jpg") center;\
                    --font_proyecto: "Changa", Helvetica, Arial, sans-serif;',
          links: {
            cta: 'http://weclapp.live',
            link_text: {es: 'landing page', en: 'landing page'}
          }
        },
        //steps
        {
          nombre: 'steps',
          nombre_logo: 'Relocation*',
          active: false,
          datos: {
            subtit: '',
            labels: {
              papel: [
                'UX', 
                'front-end', 
                'product design'
              ],
              tipo_cliente: {
                es: [
                  'Estudio', 
                  'Plataforma web'
                ],
                en: [
                  'Studio',
                  'Web platform'
                ]
              }
            }
          },
          descripcion: {
            es: 'Plataforma de gestión de usuarios, citas, viviendas, empresas y servicios interrelacionados desarrollada de forma 100% ad-hoc en base a las necesidades del cliente.',
            en: 'Platform to manage users, appointments, houses, companies and services, all interrelated. It was developed in a 100% ad-hoc way based on our customer needs.'
          },
          images: {
            // logo: 'images/proy_steps_logo.svg',
            logo_alt: 'weclapp.live, aplaude el talento',
            mockup: 'images/proy_steps_mockup.png'
          },
          estilos: '--color_proyecto: #A291D6;\
                    --fondo_proyecto: #EFEFFF;\
                    --font_proyecto: var(--font_tit_sans);', 
          links: {
            cta: 'case',
            link_text: {es: 'case study', en: 'case study'}
          }
        },
        //adderr
        {
          nombre: 'adderr',
          nombre_logo: 'Adderr',
          active: false,
          datos: {
            labels: {
              papel: [
                'UX', 
                'UI', 
                'product design',
                'front-end'
              ],
              tipo_cliente: {
                es: [
                  'Plataforma web', 
                  'App', 
                  'In-house'
                ],
                en: [
                  'Web platform', 
                  'App', 
                  'In-house'
                ]
              }
            }
          },
          descripcion: {
            es: 'Un generador de apps "white label" altamente personalizables para un público sin conocimientos de diseño y programación.',
            en: 'White label app generator that gives the power to create and publish highly personalized apps to user with no design or code knowledge'
          },
          images: {
            // logo: 'images/proy_weclapp_logo.svg',
            logo_alt: 'Adderr',
            mockup: 'images/proy_adderr_mockup.png'
          },
          estilos: '--color_proyecto: #275384;\
                    --fondo_proyecto: linear-gradient(268.56deg, rgba(136, 92, 154, 0.75) 9.01%, rgba(54, 167, 233, 0.75) 80%, rgba(54, 167, 233, 0.75) 91.46%);\
                    --font_proyecto: "Changa", Helvetica, Arial, sans-serif;',
          links: {
            cta: 'https://www.adderr.io/',
            link_text: {es: 'landing page', en: 'landing page'}
          }
        },
        //compo
        {
          nombre: 'oomoto*',
          nombre_logo: 'c*****o',
          active: false,
          datos: {
            labels: {
              papel: [
                'UX',
                'UI',
                'branding'
              ],
              tipo_cliente: {
                es: [
                  'Freelance', 
                  'Plataforma web'
                ],
                en: [
                  'Freelance', 
                  'Web platform'
                ]
              }
            }
          },
          descripcion: {
            es: 'Plataforma de compra-venta de productos entre particulares (similar a Wallapop, para un mercado distinto).<br>\
                <small class="parr_sm">*No se muestran los detalles de la marca hasta el lanzamiento de la plataforma.</small>',
            en: 'Self buy-sale plataform (similar to the spanish Wallapop, but for a different market).<br>\
                <small class="parr_sm">*the brand details will be hidden until the official launch.</small>'
          },
          images: {
            sombra: true,
            mockup: 'images/proy_compo_mockup.gif'
          },
          estilos: '--color_proyecto: #5C31F1;\
                    --fondo_proyecto: #F7CF44;\
                    --font_proyecto: "Righteous", Helvetica, Arial, sans-serif;',
          links: {
            cta: 'case',
            link_text: {es: 'ver algunos detalles', en: 'see some details'}
          }
        },
        //abuelapp
        {
          nombre: 'abuelapp',
          nombre_logo: 'abuelapp',
          active: false,
          datos: {
            labels: {
              papel: [
                'UX', 
                'UI', 
                'product design',
                'branding',
                'front-end'
              ],
              tipo_cliente: {
                es: [
                  'Experimento', 
                  'App'
                ],
                en: [
                  'Experiment', 
                  'App'
                ]
              }
            }
          },
          descripcion: {
            es: 'Queremos que esta app sea como una abuela virtual.<br>\
                Usando datos metereológicos (algo menos certeros que el “supersentido abuelil”, pero bastante fiables), te aconseja como lo haría ella.',
            en: 'We want this app to be as a virtual granma.<br>\
                By using meteorological data (less accurate than "grandma ultra sense", but pretty trustworthy), it advises you how she would.'
          },
          images: {
            sombra: true,
            mockup: 'images/proy_abuelapp_mockup.gif'
          },
          estilos: '--color_proyecto: #92B9A3;\
                    --fondo_proyecto: url("/images/proy_abuelapp_mancha.jpg") center;\
                    --font_proyecto: "Roboto", Helvetica, Arial, sans-serif;',
          links: {
            // cta: 'case',
            link_text: {es: 'pronto', en: 'soon'}
          }
        },
        //uciplus
        {
          nombre: 'uciplus',
          nombre_logo: '<b>UCI</b>PLUS',
          active: false,
          datos: {
            labels: {
              papel: [
                'UX', 
                'UI', 
                'product design',
                'branding',
                'front-end'
              ],
              tipo_cliente: {
                es: [
                  'In house', 
                  'Plataforma web'
                ],
                en: [
                  'In house', 
                  'Web platform'
                ]
              }
            }
          },
          descripcion: {
            es: '<p class="parr"> \
                  Estuve trabajando en el departamento creativo de una gran empresa de crédito inmobiliario que quería refrescar su imagen.\
                </p>\
                <p class="parr">\
                  Para ello, me hicieron responsable de <b>rediseñar y programar la web de su programa de puntos para profesionales inmobiliarios.</b>\
                </p>',
            en: '<p class="parr"> \
                  I worked at the "creative department" of a large mortgage company that wanted to refresh its image.\
                </p>\
                <p class="parr">\
                  To achieve that, they made me responsible for <b>the redesign and develop the web of its rewarding program to realtors.</b>\
                </p>'
          },
          images: {
            mockup: 'images/uci_mock.gif'
          },
          estilos: '--color_proyecto: #0862C2;\
                    --fondo_proyecto: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("/images/proy_uciplus/fondo_nar.jpg") center center;\
                    --font_proyecto: var(--font_tit_sans);',
          links: {
            cta: 'case',
            link_text: {es: 'case study', en: 'case study'}
          }
        },
        //freelance
        {
          nombre: 'freelance',
          nombre_logo: 'personalized Wordpress themes',
          active: false,
          datos: {
            labels: {
              papel: [
                'UX', 
                'UI', 
                'front-end',
                'back-end'
              ],
              tipo_cliente: {
                es: [
                  'freelance', 
                  'web'
                ],
                en: [
                  'freelance', 
                  'web'
                ]
              }
            }
          },
          descripcion: {
            es: 'He tenido varios encargos de webs con CMS para empresas (estudios de arquitectura principalmente).<br>\
                Aprendí que la fase más importante de cada proyecto, antes de centrarme en el UI y el desarrollo, es en la que, mediante herramientas como moodboards y entrevistas, definimos el estilo general (no suelen tener una identidad visual clara) y desarrollo los wireframes.',
            en: "I've dealt with several orders for the design and development of company's websites with CMS (most of them architecture studios).<br>\
                I've learned that the most important part of every project, before focusing on UI and dev, is the one where, with the help of tools as moodboards and interviews, we define what they want/need to build, set expectations, create the general style (they don't use to have a clear brand, or one at all) and the wireframes develop."
          },
          images: {
            mockup: 'images/proy_freelance_mockup.png'
          },
          estilos: '--color_proyecto: #3D3D3D;\
                    --fondo_proyecto: #fff;\
                    --font_proyecto: var(--font_tit_sans);',
          links: {}
        },
      ]
    }
  },
  watch: {
    currentScrollPosition() {
      let porfo = document.querySelector('.porfolio').offsetParent.offsetTop;
      if (window.innerWidth > 599 && (this.currentScrollPosition > porfo) ) {
        this.proyectos.forEach(proy => {
          proy.active = this.viewportActive(proy.nombre);
        });
      }
    },
    active_proy() {
      this.$emit('active_proy', this.active_proy ? this.active_proy : null);
    },
    proyectos_filtered() {
      this.$emit('proyectos', {todos: this.proyectos, filtrados: this.proyectos_filtered});
    }
  },
  computed: {
    active_proy() {
      return this.proyectos.find(proy => proy.active) || null;
    },
    proyectos_filtered() {
      let filtros = this.filtros_activos;
      let filtrados = [];
      if (!this.filtros_on) {
        filtrados = this.proyectos.filter(proy => {
          return filtros.some(fil => proy.datos.labels.papel.includes(fil));
        })
      } else {
        filtrados = this.proyectos.filter(proy => {
          return filtros.every(fil => proy.datos.labels.papel.includes(fil));
        })
      }
      return filtrados;
    }
  },
  methods: {
    viewportActive(el) {
      let div_centered = false;
      let div = document.getElementById(el);
      let top = window.innerHeight * 0.07;
      let bottom = window.innerHeight * 0.5;
      // console.log('top:' + top);
      // console.log('bottom:' + bottom);
      // // let div = this.$refs(el);
      if (div) {
        // let distance_top = div.getBoundingClientRect().top || false;
        let distance_top = (div.offsetTop + div.offsetParent.offsetTop - this.currentScrollPosition) < 0 ? 0 : div.offsetTop + div.offsetParent.offsetTop - this.currentScrollPosition;
        // console.log(el + ': ' + distance_top);
        div_centered = bottom > distance_top && distance_top > top;
      }
      return div_centered;
    }
  },
  created: function() {
    this.$emit('proyectos', {todos: this.proyectos, filtrados: this.proyectos_filtered});
  }
}
</script>

<style scoped lang="scss">
.porfolio {
  margin: 15vh 0 0;
  // .proyectos {
  //   max-height: 100vh;
  //   overflow-y: scroll;
  //   scroll-snap-type: y proximity;
  //   scroll-padding: 1.5rem;
  //   .proyecto {
  //     scroll-snap-align: center;
  //     scroll-snap-stop: always
  //   }
  // }
  @media (max-width: 599px) {
  }
}
</style>
