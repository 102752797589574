<template>
  <div class="page center intro">
    <h1 class="tit">
      <b>
        <a @click="seleccionarIdioma('es')">hola</a>
         / 
        <a class="en" @click="seleccionarIdioma('en')">hello</a></b>
    </h1>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Intro',
  computed: {
    ...mapState(['idioma']),
  },
  methods: {
    seleccionarIdioma(idioma) {
      if (idioma === 'es') {
        this.$router.push('/home');
      } else if (idioma === 'en') {
        this.$router.push('/home');
      }
      this.$store.dispatch('setIdioma', idioma);
    }
  },
}
</script>
<style scoped lang="scss">
.intro {
  background-color: var(--color_accent);
  max-width: unset;
  .tit {
    color: var(--color_base_light);
    a {
      border-bottom: .1em solid var(--color_base_light);
      margin: 0 .5em;
      // &.en {
      //   position: relative;
      //   color: rgba(255,255,255,.1);
      //   border-bottom-color: rgba(255,255,255,.1);
      //   cursor: default;
      //   &:after {
      //   transition: width .5s ease-in;
      //     content: "not ready jet 😅";
      //     position: absolute;
      //     top: 100%;
      //     left: 0;
      //     // color: var(--color_accent);
      //     color: var(--color_base_light);
      //     font-size: clamp(1rem, 1.2vw, 1.5rem);
      //     font-family: var(--font_parr_ser);
      //     width: 0%;
      //     height: 2em;
      //     padding: 1em 1em 1em 0;
      //     opacity: 0;
      //     overflow-x: hidden;
      //     white-space: nowrap;
      //   }
      //   &:hover:after {
      //     width: 120%;
      //     opacity: 1;
      //   }
      // }
    }
  }
}
</style>
