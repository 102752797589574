import { createRouter, createWebHashHistory } from 'vue-router'
import Home from './views/Home.vue'
import Intro from './views/Intro.vue'
// import About from './views/About.vue'
//case studies
import Uciplus from './views/proyectos/Uciplus.vue'
import Steps from './views/proyectos/Steps.vue'
import Compo from './views/proyectos/Compo.vue'
import Abuelapp from './views/proyectos/Abuelapp.vue'

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/',
    name: 'Intro',
    component: Intro
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/case/uciplus',
    name: 'uciplus',
    component: Uciplus
  },
  {
    path: '/case/steps',
    name: 'steps',
    component: Steps
  },
  {
    path: '/case/oomoto*',
    name: 'oomoto*',
    component: Compo
  },
  {
    path: '/case/abuelapp',
    name: 'abuelapp',
    component: Abuelapp
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: About
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes
})

export default router
