<template>
<div>
  <transition name="slide_top">
    <p v-if="idioma && showNavbar"
      class="datos cambiar_idioma" 
      @click.prevent="cambiaIdioma"
    >
      {{idioma === 'en' ? 'ES' : idioma === 'es' ? 'EN' : ''}}
    </p>
  </transition>
  <router-view class="container" :currentScrollPosition="currentScrollPosition"/>
  <transition name="slide_bottom">
    <aside v-if="idioma && showNavbar" @mouseenter="showLinks = true" @mouseleave="showLinks = false"
      class="menu" 
      :style="{cursor: $route.name != 'Home' ? 'pointer' : ''}"  
    >
      <div v-if="showLinks" class="links">
        <a class="datos" href="http://www.linkedin.com/in/robertomesejo" target="blank">linkedIn</a>
        <a class="datos" 
          :href="idioma === 'es' ? 'mailto:japimes@gmail.com?subject=Soy ....., Chief of ..... en .....&body=Hola! He llegado a tu web desde ..... y antes de decirte lo que te quiero decir me gustaría comentarte que tu web me parece ..... Si hay algún detalle que me encanta menos podría ser ..... Bueno, al grano:' : 'mailto:japimes@gmail.com?subject=I am ....., Chief of ..... at ..... &body=Hi! I know you will apreciate some tiny feedback from my side first, so I just want to tell you that the thing I like the most from your site was ....., but you could improve something as ..... . So...where was I..., yes:'" target="blank">email</a>
      </div>
      <svg class="icon_contact" width="89" height="89" viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44.2 80.4C65.2973 80.4 82.4 63.2973 82.4 42.2C82.4 21.1027 65.2973 4 44.2 4C23.1027 4 6 21.1027 6 42.2C6 63.2973 23.1027 80.4 44.2 80.4Z" fill="white"/>
        <path d="M67.8 42.2C67.8 55.3 57.2 65.8 44.2 65.8C31.2 65.8 20.5 55.2 20.5 42.2" fill="white"/>
        <path d="M67.8 42.2C67.8 55.3 57.2 65.8 44.2 65.8C31.2 65.8 20.5 55.2 20.5 42.2M82.4 42.2C82.4 63.2973 65.2973 80.4 44.2 80.4C23.1027 80.4 6 63.2973 6 42.2C6 21.1027 23.1027 4 44.2 4C65.2973 4 82.4 21.1027 82.4 42.2Z" stroke="#12328B" stroke-width="3.9169" stroke-miterlimit="10"/>
      </svg>
      <span class="datos back" v-if="showLinks && $route.name != 'Home'" @click="$router.push({name: 'Home'})">←home</span>
    </aside>
  </transition>
</div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: "App",
  data() {
    return {
      showNavbar: true,
      showLinks: false,
      lastScrollPosition: 0,
      currentScrollPosition: 0
    }
  },
  computed: {
    ...mapState(['idioma']),
  },
  methods: {
    onScroll () {
      this.currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (this.currentScrollPosition >= 0 && (Math.abs(this.currentScrollPosition - this.lastScrollPosition) > 60)) {
        this.showNavbar = this.currentScrollPosition < this.lastScrollPosition
        this.lastScrollPosition = this.currentScrollPosition
      }
    },
    cambiaIdioma() {
      if (this.idioma) this.$store.dispatch('setIdioma', this.idioma === 'en' ? 'es' : 'en');
    }
  },
  created: function() {
    let idioma = localStorage.getItem('lang');
    if (!idioma) this.$router.push('/');
  },
  mounted () {
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll);
    console.log(this.$route.name);
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
<style lang="scss">
#app {
  // overflow-x: hidden;
  .cambiar_idioma {
    position: fixed;
    top: 1rem;
    right: 1rem;
    color: var(--color_accent);
    cursor: pointer;
    z-index: 100;
    &:hover {font-weight: bold;}
  }
  .menu {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 6em;
    display: flex;
    flex-flow: column;
    align-items: center;
    // justify-content: left;
    padding: .5em;
    svg {
      width: clamp(3.5rem, 5vw, 5rem);
      height: auto;
      // cursor: pointer;
      filter: drop-shadow(0 0 4px rgba(18, 50, 139, 0.25));
      &.back {
        cursor: pointer;
      }
    }
    .back {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 12.5%;
      width: 75%;
      text-align: center;
      background-color: rgba(255, 255, 255, .5);
      backdrop-filter: blur(1px);
      padding: 2em 0;
      // color: var(--color_accent);
    }
    .links {
      padding: .7em;
      margin-bottom: .5em;
      border-radius: .5em;
      background-color: white;
      box-shadow: 0 0 4px rgba(0, 0, 0, .05);
      a {
        font-weight: bold;
        display: block;
        text-align: center;
        &:first-child {margin-bottom: 1.2em;}
      }
    }
  }
  //transitions
  .slide_bottom-enter-active, .slide_bottom-leave-active {
    transition: bottom .5s ease-in-out;
  }
  .slide_bottom-enter-from, .slide_bottom-leave-to {
    bottom: -15vh;
  }

  .slide_top-enter-active, .slide_top-leave-active {
    transition: top .5s ease-in-out;
  }
  .slide_top-enter-from, .slide_top-leave-to {
    top: -15vh;
  }

  .slide_left-enter-active, .slide_left-leave-active {
    transition: 
      left .5s ease-in-out,
      opacity .4s ease-in;
  }
  .slide_left-enter-from {
    opacity: 0;
    left: 10%;
  }
  .slide_left-leave-to {
    opacity: 0;
    left: -10%;
  }
  
  .slide_right-enter-active, .slide_right-leave-active {
    transition: 
      left .5s ease-in-out,
      opacity .4s ease-in;
  }
  .slide_right-enter-from {
    opacity: 0;
    left: -10%;
  }
  .slide_right-leave-to {
    opacity: 0;
    left: 10%;
  }

  .appear_right-enter-active {
    transition: width .5s .5s ease-in-out,
                opacity 1s ease-in;
  }
  .appear_right-leave-active {
    transition: width .5s ease-in,
                opacity .3s ease-out;
  }
  .appear_right-enter-from, .appear_right-leave-to {
    width: 0;
    opacity: 0;
    img {
      width: 80%;
      object-position: 20em center;
    }
  }

  .appear_top-enter-active {
    transition: max-height 1s .8s ease-in-out,
                opacity 0s;
  }
  .appear_top-leave-active {
    transition: max-height .5s ease-in-out,
                opacity .5s ease-in-out;
  }
  .appear_top-enter-from, .appear_top-leave-to {
    max-height: 0;
    opacity: .2;
  }
}
</style>
