<template>
  <div class="case">
    <section class="intro">
      <volver />
      <p class="parr small">{{textos[idioma].ellogo}}</p>
      <h1 class="tit">
        Oomoto*
      </h1>
    </section>
    <section class="resum">
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].objetivo}}</p>
        <h3 class="subtit">{{textos[idioma].objetivo_texto}}</h3>
      </div>
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].labor}}</p>
        <div class="res_icons">
          <p class="datos" v-html="textos[idioma].labor_texto1"></p>
          <p class="datos" v-html="textos[idioma].labor_texto2"></p>
        </div>
      </div>
      <div class="subseccion">
        <p class="parr descriptivo">{{textos[idioma].donde}}</p>
        <p class="parr donde">
          <span><b>2018</b></span><br>
          <span><b>Freelance</b></span>
        </p>
      </div>
    </section>
    <section class="contexto">
      <h3 class="tit">{{textos[idioma].dequevaesto}}</h3>
      <p class="parr" v-html="textos[idioma].dequevaesto_texto1"></p>
      <p class="parr" v-html="textos[idioma].dequevaesto_texto2"></p>
    </section>
    <section class="detalles">
      <h3 class="tit">{{textos[idioma].algunosdetalles}}</h3>
      <article class="imagen">
        <p class="parr">{{textos[idioma].modalsignup}}</p>
        <div class="imagenes">
          <img class="mockup bg" src="/images/proy_compo/login.gif" />
          <img class="mockup sm" src="/images/proy_compo/login_mob.jpg" />
        </div>
      </article>
      <article class="imagen">
        <p class="parr">{{textos[idioma].homepage}}</p>
        <div class="imagenes">
          <img class="mockup" src="/images/proy_compo/muro.jpg" />
        </div>
      </article>
      <article class="imagen">
        <p class="parr">{{textos[idioma].filtros}}</p>
        <div class="imagenes">
          <img class="mockup nobg" src="/images/proy_compo/filtros.png" />
        </div>
        <p class="datos">{{textos[idioma].muypersonalizado}}</p>
      </article>
      <article class="imagen">
        <p class="parr">{{textos[idioma].ordenarresultados}}</p>
        <div class="imagenes">
          <img class="mockup" src="/images/proy_compo/muro_sort.jpg" />
        </div>
      </article>
      <article class="imagen">
        <p class="parr">{{textos[idioma].subidaproducto}}</p>
        <div class="imagenes">
          <img class="mockup sm" src="/images/proy_compo/subelo3_mob.jpg" />
        </div>
        <p class="datos">{{textos[idioma].simpleypersonalizado}}</p>
      </article>
      <article class="imagen">
        <p class="parr">{{textos[idioma].chat}}</p>
        <div class="imagenes">
          <img class="mockup" src="/images/proy_compo/chat.jpg" />
        </div>
        <p class="datos">{{textos[idioma].mantienevisible}}</p>
      </article>
      <article class="imagen">
        <p class="parr">{{textos[idioma].promocion}}</p>
        <div class="imagenes">
          <img class="mockup sm" src="/images/proy_compo/destacalo.jpg" />
        </div>
      </article>
    </section>
    <futer_cases />
  </div>
</template>

<script>
import futer_cases from "@/components/futer_cases.vue"
import volver from "@/components/volver.vue"
import { mapState } from 'vuex'

export default {
  name: 'compota',
  components: {futer_cases, volver},
  data() {
    return {
      textos: {
        es: {
          ellogo: '*El logo y otros elementos de la marca han sido modificados hasta el lanzamiento oficial de la plataforma, que se encuentra actualmente en producción.',
          objetivo: 'Objetivo:',
          objetivo_texto: 'Creación de una marca y una plataforma en sintonía con unos valores y un mercado',
          labor: 'Labor en el proyecto:',
          labor_texto1: '<b>Branding y sistema de diseño</b> <small>.fig</small>',
          labor_texto2: '<b>Diseño UI/UX</b> <small>.figma</small>',
          donde: 'Dónde y cuando:',
          dequevaesto: '¿De qué va esto?',
          dequevaesto_texto1: '****** se trata de una plataforma de compra-venta de productos entre particulares. Sí, la idea es muy similar a Wallapop pero está enfocada a otro mercado diferente.',
          dequevaesto_texto2: 'Precisamente, mi labor principal, a parte de crear toda la interfaz, ha sido la de <b>adaptar el producto al mercado al que se dirige</b>, para lo que realicé un estudio de la cultura visual, las costumbres relacionadas con la tecnología móvil, y el tipo de comportamiento en este tipo de plataformas C2C.',
          algunosdetalles: 'Algunos detalles visuales',
          modalsignup: 'Modal de login/signup',
          homepage: 'Home page',
          filtros: 'Filtros de productos',
          muypersonalizado: 'Muy personalizado a cada categoría, para ser lo más útil posible',
          ordenarresultados: 'Ordenar resultados',
          subidaproducto: 'Detalle de subida de producto',
          simpleypersonalizado: 'Simple y personalizado a cada categoría',
          chat: 'Detalle del chat con vendendor',
          mantienevisible: 'Mantiene visible la info del producto para añadir contexto',
          promocion: 'Detalle de promoción'
        },
        en: {
          ellogo: '*The logo and other elements of the brand have been modified until the platform official launch, currently under development.',
          objetivo: 'Goal:',
          objetivo_texto: 'Creation of a new brand and platform in sintony with their values and market',
          labor: 'My role:',
          labor_texto1: '<b>Branding and design system</b> <small>.fig</small>',
          labor_texto2: '<b>UI/UX design</b> <small>.figma</small>',
          donde: 'When and where:',
          dequevaesto: 'What is this about?',
          dequevaesto_texto1: '****** is a marketplace CTC for selling and buying products between individuals.',
          dequevaesto_texto2: 'My main job, beyond creating all the UI, was <b>adapting the product to their market</b>. For achieving this I reseach their visual culture, their mobile tech adoption and ways of use, and their behavior and familiarity with these kind of C2C platforms.',
          algunosdetalles: 'Some visual details',
          modalsignup: 'Login/signup modal',
          homepage: 'Home page',
          filtros: 'Product filters',
          muypersonalizado: 'Personalized to each category, to be as usefull as possible',
          ordenarresultados: 'Sort results',
          subidaproducto: 'Upload product',
          simpleypersonalizado: 'Simple and personalized',
          chat: 'Chat with the seller',
          mantienevisible: "It keeps the product's info visible, to bring context to the chat",
          promocion: 'Promotion of a product'
        }
      }
    }
  },
  watch: {
  },
  computed: {
    ...mapState(['idioma'])
  },
  methods: {
  },
  mounted: function() {}
}
</script>

<style scoped lang="scss">
.case {
  --case_primario: #5C31F1;
  --case_fondo: #F7F6F2;
  --case_textos: #333333;

  --font_parr_sans: 'Raleway', Helvetica, Arial, sans-serif;

  ::selection {background-color: var(--case_primario); color: white;}
  .tit {
    font-family: var(--font_parr_sans);
	}
	.parr-grande {
    font-family: "Righteous", "Times New Roman", Times, serif;
		line-height: 1.5em;
	}
  background-color: var(--case_fondo);
}
section {
  position: relative;
  max-width: 90ch;
  margin: 0 auto 20vh;
  .subtit {
    margin-bottom: .5em;
    color: var(--case_primario);
  }
  article {
    margin-bottom: 2em; 
    .subtit {font-size: .5em;}
  }
}
.intro {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-top: 5vh;
  .tit {
    padding-top: 15vh;
    color: var(--case_primario);
    // position: fixed;
    // top: 25vh;
    font-size: 7vw;
    margin-top: 0;
  }
  .small {font-size: 1em; margin-top: 1em;}
}
.resum {
  margin: 10vh auto 15vh;
  .subseccion {
    display: flex;
    margin: 1em auto;
    .descriptivo {width: 12em;}
  }
}
.detalles {
  position: relative;
  max-width: unquote('min(80vw,110ch)');
  .tit {max-width: 90vh; margin: 0 auto .5em;}
  .imagen {
    margin-bottom: 20vh;
    .imagenes {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mockup {
        box-shadow: 0px 4px 4px rgba(247, 209, 68, 0.25);
        border-radius: 2px;
        border: 3px solid #FFFFFF;
        width: 80%;
        margin: 0 auto;
        &.bg {width: 70%; margin-right: 3em;}
        &.sm {width: 25%;}
        &.nobg {
          width: 70%;
          border: none; 
          box-shadow: none;
          filter: drop-shadow(0 4px 4px rgba(247, 209, 68, 0.25));
        }
      }
    }
    .datos {
      font-size: 1em;
      text-align: center;
      font-style: italic;
    }
  }
}

@media (max-width: 599px) {
  .container.case {
    overflow: unset;
  }
  section {
    padding: 0 15px;
  }
  .intro {
    height: 70vh;
    .tit {
      font-size: 15vw;
      margin-top: 20vh;
      padding: 0 15px;
    }
  }
  .resum {
    .subseccion {
      flex-flow: column;
      text-align: center;
      align-items: center;
      margin-top: 2em;
      .descriptivo {margin-right: 0;}
    }
  }
  .detalles {
    max-width: inherit;
    .imagenes {
      flex-wrap: wrap;
      .mockup.sm {margin-top: 2em;}
    }
  }
}
</style>
